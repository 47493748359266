@import 'styles/constants.scss';

.documentOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $spacing-sm 0;
  transition: $transition-base;
  cursor: pointer;
  &:hover {
    color: $color-primary;
  }
}

.documentOptionIcon {
  width: 24px;
  height: 16px;
  margin-right: $spacing-md;
}

.documentOptionText {
  margin-right: auto;
  text-transform: capitalize;
  font-size: $font-md;
  font-weight: $weight-medium;
  line-height: $line-height-lg;
}

.arrowIcon {
  width: 16px;
  height: 16px;
  color: $color-primary;
  stroke-width: 2px;
}
