@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.marqueeWrapper {
  display: flex;
  position: sticky;
  z-index: $z-index-navbar;
  top: 0;
  flex-direction: row;
  height: calc(48px + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  background-color: black;
}

.marqueeBannerText {
  color: white;
  padding-inline: 60px;
}
