@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.backIcon {
  position: absolute;
  top: $spacing-md;
  left: $spacing-md;
  width: 24px;
  height: 24px;
  cursor: pointer;
  @media screen and (max-width: $screen-md) {
    position: static;
    top: $spacing-md;
    left: calc((100% - $max-width) / 2);
    margin: 0 auto 0 0;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $spacing-sm;
  gap: $spacing-lg;
}

.header {
  composes: headerLarge;
}

.description {
  padding: 0 $spacing-md;
  text-align: center;
  color: $color-gray-500;
  composes: paragraphSmall;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.wireframeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 256px;
  flex-shrink: 0;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: $spacing-sm;
}
