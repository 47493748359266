@import 'styles/classes/common.module.scss';

.balance {
  padding: $spacing-sm 0;
  text-align: center;
  font-weight: $weight-semibold;
  line-height: $line-height-xl;
  color: $color-primary;
  cursor: pointer;
}

.accountBalanceSpinner {
  width: 24px;
  height: 24px;
  border-top: 2px solid $color-primary-alt;
  margin-inline: auto;
}
