@import 'styles/constants.scss';

.tabBarContainer {
  display: none;
  position: fixed;
  z-index: $z-index-navbar;
  bottom: 0;
  left: 0;
  width: 100%;
  @media screen and (max-width: $screen-sm) {
    display: block;
  }
}

.tabBar {
  background-color: white;
}

.tabButton {
  background-color: white;
}

.tabLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: $spacing-2xs;
  background-color: white;
  color: inherit;
}

.active {
  color: $color-primary;
}

.tabIcon {
  width: 24px;
  height: 24px;
}

.tabLabel {
  font-size: $font-2xs;
}
