@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.copyToClipboardWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.copyWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
  }
}

.disabledCopyWrapper {
  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.label {
  font-weight: $weight-semibold;
}

.readyOnlyInput {
  width: 100%;
  height: 48px;
  padding: $spacing-sm;
  font-weight: $weight-normal;
  composes: baseInputStyling;
  &:hover {
    box-shadow: none;
  }
  &:focus,
  &:active {
    border: 1px solid $color-gray-300;
  }
}

.copyButton {
  width: 24px;
  color: $color-gray-500;
  transition: $transition-base;
  cursor: pointer;

  &:hover {
    color: black;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
}

.checkIcon {
  width: 24px;
}

.copiedLabel {
  width: fit-content;
  padding: $spacing-4xs $spacing-sm;
  border: 1px solid $color-primary-alt;
  border-radius: $border-xl;
  font-size: $font-xs;
  color: $color-primary-alt;
}

.copyPrimaryButton {
  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.disabledMessage {
  color: $color-red;

  &.mobile {
    @media screen and (min-width: $screen-sm) {
      display: none;
    }
  }

  &.desktop {
    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }
}
