@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

$star-size: 20px;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $spacing-2xl $spacing-lg;
  border-radius: $border-sm;
  margin: 0 auto $spacing-2xl;
  background-color: $color-gray-100;
  gap: $spacing-lg;
}

.title {
  composes: headerMedium;
}

.terms {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.term {
  display: flex;
  gap: $spacing-2xs;
}

.icon {
  width: $star-size;
  min-width: $star-size;
  height: $star-size;
  min-height: $star-size;
}

.text,
.termsHyperLink {
  composes: paragraphSmall;
}
