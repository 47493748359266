@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.campaignLogo {
  width: min-content;
  max-height: 70px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-lg;
  gap: $spacing-xs;
}

.title {
  font-weight: $weight-semibold;
  composes: headerHuge;

  &::first-letter {
    text-transform: uppercase;
  }

  @media screen and (max-width: $screen-sm) {
    font-size: $font-xl;
    line-height: $line-height-md;
  }
}

.info {
  color: $color-gray-600;
  composes: paragraphRegular;
}

.rewardInfo {
  composes: info;
}

.link {
  composes: basicLink;
}
