@import 'styles/constants.scss';

.dragAndDrop {
  width: 100%;
  height: 100%;
  transition: $transition-base;
  cursor: pointer;
}

.dragAndDropContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dragging {
  opacity: 0.5;
  composes: dragAndDropContents;
  cursor: alias;
}

.inputFile {
  display: none;
}

.uploadBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border: 1px dashed $color-gray-500;
  border-radius: $border-xs;
  img {
    width: 100%;
    border-radius: $border-xs;
  }
  object {
    width: 100%;
    min-height: 200px;
    border-radius: $border-xs;
  }
}

.uploadBoxActive {
  overflow: hidden;
  width: 100%;
  height: fit-content;
  max-height: 240px;
  padding: 0 $spacing-lg;
  border: 0;
  composes: uploadBox;
}

.uploadBoxError {
  border: 1px dashed $color-red;
  composes: uploadBox;
}

.documentImage {
  width: 100%;
  max-height: 100%;
}

.documentIcon {
  width: 48px;
  color: $color-gray-400;
}

.documentIconActive {
  color: $color-primary-alt;
  composes: documentIcon;
}

.dragAndDropText {
  margin-top: $spacing-sm;
  text-align: center;
  font-size: $font-xs;
  font-weight: $weight-normal;
  color: $color-gray-500;
}

.dragAndDropTextActive {
  color: $color-primary;
  composes: dragAndDropText;
}
