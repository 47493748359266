@import 'styles/constants.scss';

.bisector {
  opacity: 0;
  transition: $transition-base;
  &.active {
    opacity: 1;
  }
}

.line {
  stroke: $color-gray-500;
  stroke-width: 1;
}

.highlight {
  fill: $color-primary;
  transition: $transition-base;
  transition-property: fill;
  stroke: $color-gray-100;
  stroke-width: 2;
  &.gray {
    fill: $color-gray-400;
  }
}

.shadow {
  fill: rgba(187, 187, 187, 0.2);
  &.gray {
    fill: rgba(187, 187, 187, 0.2);
  }
}
