@import 'styles/constants.scss';

.radioWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: $spacing-sm;
}

.radio {
  display: none;
}

.label {
  position: relative;
  padding-left: $spacing-lg;
  cursor: pointer;
}

// unselected state
.label::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 1px solid $color-gray-500;
  border-radius: 50%;
  outline: 0 solid $color-gray-200;
  transition: $transition-base;
}

// unselected hover state
.label:hover::before {
  background-color: $color-gray-200;
  outline: 10px solid $color-gray-200;
}

// selected state
.radio:checked + .label::before {
  border: 6px solid $color-primary;
  outline: 0 solid $color-accent;
}

// selected hover state
.radio:checked + .label:hover::before {
  background-color: $color-accent;
  outline: 10px solid $color-accent;
}
