.graphCanvas {
  display: block;
  width: 100%;
  height: 100%;
}

.mainGroup {
  width: 100%;
  height: 100%;
}
