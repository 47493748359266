@import 'styles/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-sm) {
    justify-content: space-between;
    padding: 0;
    margin: 0;
    box-shadow: none;
    flex-grow: 1;
  }
}

.noBoxShadow {
  box-shadow: none;
}
