@import 'styles/classes/text.module.scss';

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xl;
}

.artistSection,
.masterSection {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.title {
  composes: headerLarge;

  @media screen and (max-width: $screen-xs) {
    font-size: $font-xl;
  }
}
