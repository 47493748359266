@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.cancelOrderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  composes: headerLarge;
}

.subtitle {
  color: $color-gray-600;
  composes: paragraphRegular;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-md;
  gap: $spacing-sm;
}
