@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-md;
  gap: $spacing-2xs;

  @media screen and (max-width: $screen-sm) {
    justify-content: flex-end;
    height: 50%;
  }
}

.title {
  composes: headerLarge;
  @media screen and (max-width: $screen-sm) {
    text-align: center;
  }
}

.errorDescription {
  margin-bottom: $spacing-md;
  font-size: $font-md;
  font-weight: $weight-normal;
  color: $color-gray-600;
  @media screen and (max-width: $screen-sm) {
    text-align: center;
  }
}

.email {
  composes: basicLink;
}
