@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.revenueSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: $screen-md) {
    flex-wrap: wrap;
    row-gap: $spacing-md;
  }
  @media screen and (max-width: $screen-xs) {
    flex-direction: column;
  }
}
