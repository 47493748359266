@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.orderTypesContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;

  @media screen and (max-width: $screen-sm) {
    justify-content: center;
  }
}

.title {
  text-align: center;
  composes: headerLarge;
}

.message {
  text-align: center;
  font-size: $font-sm;
  color: $color-gray-600;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;

  @media screen and (max-width: $screen-sm) {
    // justify-content: flex-end;
    // height: 50%;
  }
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-2xs;
}

.button {
  min-width: 180px;
  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}
