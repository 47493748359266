@import 'styles/classes/text.module.scss';

.errorContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.errorIcon {
  width: 24px;
  height: 24px;
}

.title {
  composes: headerLarge;
}

.errorDescription {
  margin-bottom: $spacing-md;
  font-size: $font-md;
  font-weight: $weight-normal;
  color: $color-gray-600;
}

.retryButton {
  @media screen and (max-width: $screen-sm) {
    margin-top: auto;
  }
}
