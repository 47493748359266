@import 'styles/classes/common.module.scss';

.metricsContent {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
}

.platformMetricsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;
  row-gap: $spacing-md;

  @media screen and (max-width: $screen-md) {
    margin-top: $spacing-md;
  }
}

.metricHeader {
  margin-bottom: $spacing-3xs;
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.totalStreams {
  display: inline-flex;
  flex-direction: row;
  margin-top: $spacing-sm;
  font-weight: $weight-bold;
  gap: $spacing-3xs;
}
.boldText {
  font-size: $font-md;
  font-weight: $weight-semibold;
}
