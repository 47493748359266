@import 'styles/constants.scss';

.container {
  display: none;
  position: fixed;
  z-index: $z-index-overlay;
  right: 0;
  bottom: 0;
  left: 0;

  @media screen and (max-width: $screen-sm) {
    display: unset;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-md;
  background-color: black;
  color: white;
  gap: $spacing-2xs;
}

.title {
  font-weight: $weight-semibold;
}

.description {
  text-align: center;
}
