@import 'styles/constants.scss';

.paper {
  border-radius: $border-sm;
  background-color: $color-gray-100;
}

.paperWithPadding {
  padding: $spacing-sm $spacing-md;
  composes: paper;
}
