@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.tag {
  width: fit-content;
  padding: 0 $spacing-2xs;
  border-radius: $border-full;
  background-color: $color-primary;
  font-size: $font-sm;
  color: white;
}

.masterHeaderContainer {
  position: relative;
  width: 100%;
  height: 360px;
  &::after {
    content: '';
    position: absolute;
    z-index: $z-index-base;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.4);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 10%,
      rgba(0, 0, 0, 0.4) 20%,
      rgba(0, 0, 0, 0.2) 35%,
      rgba(0, 0, 0, 0) 50%
    );
  }

  @media screen and (max-width: $screen-sm) {
    height: 400px;
    &::after {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 5%,
        rgba(0, 0, 0, 0) 35%,
        rgba(0, 0, 0, 0) 65%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }
}

.masterOwnerWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.masterInfoContainer {
  display: flex;
  position: absolute;
  z-index: $z-index-overlay;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  composes: maxWidthContainer;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.masterInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: $spacing-md 0;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-sm) {
    justify-content: space-between;
  }
}

.heading {
  margin-top: $spacing-sm;
  color: white;
  composes: headerLarge;
}

.description {
  color: white;
  composes: headerSmall;
}

.masterDescription {
  display: flex;
  flex-direction: column;
  color: white;
  gap: $spacing-sm;
}

.thumbnail {
  width: 64px;
  height: 64px;
  border-radius: $border-xs;
}

.songDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  gap: $spacing-sm;
}

.autoScroll {
  padding-inline: $spacing-2xs;
}

.masterName {
  font-size: $font-xl;
  font-weight: $weight-bold;
}

.artistName {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.shareIcon {
  position: absolute;
  top: $spacing-lg;
  right: $spacing-lg;
  padding: 0;
  composes: iconContainer;
}
