@import 'styles/constants.scss';

.title {
  font-size: $font-md;
  color: $color-gray-600;
}

.info {
  font-size: $font-xs;
  color: $color-gray-500;
}
