@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

$modal-min-height: 408px;
$modal-max-height: 100vh;
$modal-max-height-mobile: 100dvh;
$modal-width-sm: 380px;
$modal-width-md: 496px;
$modal-width-lg: 864px;

.modalWrapper {
  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.65;
  width: 100%;
  height: 100%;
  background-color: black;
}

.modalContainer {
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  overflow-y: auto;
  min-height: $modal-min-height;
  max-height: $modal-max-height;
  @media screen and (max-width: $screen-sm) {
    max-height: $modal-max-height-mobile;
  }
}

.modalContainerSmall {
  left: calc((100% - $modal-width-sm) / 2);
  width: $modal-width-sm;
  composes: modalContainer;
}

.modalContainerMedium {
  left: calc((100% - $modal-width-md) / 2);
  width: $modal-width-md;
  composes: modalContainer;
  @media screen and (max-width: $screen-sm) {
    left: 0;
    width: 100%;
    height: 100%;
    max-height: unset;
  }
}

.modalContainerLarge {
  left: calc((100% - $modal-width-lg) / 2);
  width: $modal-width-lg;
  composes: modalContainer;
  @media screen and (max-width: $screen-md) {
    left: $spacing-sm;
    width: calc(100% - $spacing-lg);
  }
  @media screen and (max-width: $screen-sm) {
    left: 0;
    width: 100%;
    height: 100%;
    max-height: unset;
  }
}

.modal {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: calc($spacing-md + env(safe-area-inset-top));
  padding-bottom: calc($spacing-md + env(safe-area-inset-bottom));
  border-radius: $border-sm;
  background-color: white;
  composes: elevatedUi;
  padding-inline: $spacing-md;

  &.noPadding {
    padding: 0;
    border: 0;
  }

  @media screen and (max-width: $screen-sm) {
    height: 100%;
    max-height: unset;
    padding-top: calc($spacing-sm + env(safe-area-inset-top));
    padding-bottom: calc($spacing-sm + env(safe-area-inset-bottom));
    border-radius: 0;
    padding-inline: $spacing-sm;
  }
}

.closeIcon {
  transform: translate(-5px, -5px);
  width: 32px;
  height: 32px;
  margin-bottom: $spacing-sm;
  composes: iconContainer;
}
