@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.song {
  width: 130px;
}

.artist {
  width: 120px;
}

.type {
  width: 140px;
}

.id {
  width: 100px;
}

.quantity {
  width: 40px;
}

.price {
  width: 60px;
}

.totalPrice {
  width: 60px;
}

.thumbnail {
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 24px;
}

.thumbnailImg {
  width: 24px;
  height: 24px;
  border-radius: $border-xs;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  composes: iconContainer;
}

.cancelOrder {
  width: 40px;
  height: 40px;
}
