@import 'styles/constants.scss';

.orderLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 440px;
  gap: $spacing-md;
}

.loadingAnimation {
  width: 64px;
  height: 64px;
}

.loadingText {
  margin-bottom: $spacing-xl; // compensate for the space taken by close button
  font-size: $font-lg;
  font-weight: $weight-semibold;
}
