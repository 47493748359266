@import 'styles/constants.scss';

.metric {
  display: flex;
  width: 33.33%;
  font-size: $font-md;
  gap: $spacing-2xs;
  @media screen and (max-width: $screen-md) {
    width: 50%;
  }
}

.platformName {
  font-size: $font-md;
  font-weight: $weight-semibold;
  color: black;
}

.platformValue {
  font-size: $font-md;
  font-weight: $weight-normal;
}

.placeholder {
  width: 24px;
  height: 24px;
}

.icon {
  width: 24px;
  height: 24px;
}
