@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.imoEmptyStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-xl 0;
  text-align: center;
  gap: $spacing-xl;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $empty-state-width;
  gap: $spacing-md;
}

.sectionTitle {
  composes: headerLarge;
}

.subtitle {
  color: $color-gray-500;
  composes: paragraphRegular;
}
