@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

@import 'styles/classes/common.module.scss';

.pillSelectContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.pillOptionsList {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-2xs;
}

.pillWrapper {
  composes: pill;
  composes: noSelect;
  gap: $spacing-2xs;
}

.active {
  border-color: $color-accent;
  background-color: $color-accent;
  color: $color-primary;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.closeIcon {
  width: 18px;
  height: 18px;
}
