@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.routeLink {
  font-size: $font-sm;
  font-weight: $weight-medium;
  color: black;

  &.routeLinkActive {
    color: $color-primary;
  }
}

.dropdown {
  font-weight: $weight-normal;
}
