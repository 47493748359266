@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.container {
  display: flex;
  gap: $spacing-sm;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: $border-full;
  background-color: black;
  font-size: $font-sm;
  color: white;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  padding-top: $spacing-3xs;
  gap: $spacing-xs;
}

.title {
  composes: headerMedium;
}

.description {
  color: $color-gray-500;
  composes: paragraphRegular;
}
