@import 'styles/constants.scss';

.backIcon {
  position: absolute;
  top: $spacing-md;
  left: $spacing-md;
  width: 24px;
  height: 24px;
  cursor: pointer;
  @media screen and (max-width: $screen-md) {
    position: static;
    top: $spacing-md;
    left: calc((100% - $max-width) / 2);
    margin: 0 auto 0 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-md;
  gap: $spacing-2xs;
  @media screen and (max-width: $screen-md) {
    margin-top: 0;
  }
}

.title {
  text-transform: capitalize;
}

.subtitle {
  font-size: $font-md;
  font-weight: $weight-normal;
  line-height: $line-height-md;
  color: $color-gray-600;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.wireframeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 256px;
  flex-shrink: 0;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.guidelines {
  padding: 0 $spacing-md 0 $spacing-lg;
  text-align: left;
  composes: subtitle;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: $spacing-sm;
}
