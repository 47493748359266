@import 'styles/constants.scss';

.lineGraphContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 304px;
  padding: $spacing-md;
  padding-top: 0;
  transition: $transition-base;
  touch-action: pan-y;
}

.lineGraphSvg {
  display: block;
  width: 100%;
  height: 100%;
  cursor: default;
}

.lineGraph {
  width: 100%;
  height: 100%;
}

.linePath,
.interpolatorPath {
  fill: none;
  stroke: $color-primary;
  stroke-width: 2;
}

.imoLinePath {
  fill: none;
  stroke: $color-gray-300;
  stroke-width: 2;
}

.graphLegend {
  position: absolute;
  opacity: 0;
  width: fit-content;
  border-radius: $border-xs;
  font-size: $font-3xs;
  transition: $transition-base;
  transition-property: opacity;
  pointer-events: none;
  &.active {
    opacity: 1;
  }
}

.notch {
  position: absolute;
  bottom: -9px;
  transform: translate(-50%) rotate(45deg);
  width: 16px;
  height: 16px;
  border-right: 1.5px solid $color-gray-300;
  border-bottom: 1.5px solid $color-gray-300;
  border-radius: 0 0 $border-xs;
  background-color: white;
}

.price {
  font-weight: $weight-bold;
}

.timestamp {
  color: $color-gray-600;
}

.bisector {
  opacity: 0;
  transition: $transition-base;
  &.active {
    opacity: 1;
  }
}

.line {
  stroke: $color-gray-500;
  stroke-width: 1;
}

.crosshair {
  fill: $color-primary;
  transition: $transition-base;
  transition-property: fill;
  stroke: $color-gray-100;
  stroke-width: 2;
  &.gray {
    fill: $color-gray-400;
  }
}

.shadow {
  fill: rgba(187, 187, 187, 0.2);
  &.gray {
    fill: rgba(187, 187, 187, 0.2);
  }
}

.xAxis,
.yAxis {
  font-size: $font-xs;
  color: $color-gray-600;
  user-select: none;
}

.xAxis {
  text-anchor: start;
}
