@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.tradingCommissionInfoContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.info {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.header {
  composes: headerLarge;
}

.title {
  font-size: $font-md;
  font-weight: $weight-medium;
  composes: paragraphRegular;
}

.subtitle {
  font-size: $font-sm;
  color: $color-gray-600;
}
