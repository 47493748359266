@import 'styles/classes/common.module.scss';

.shareIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-2xs;
}

.shareButtons {
  display: flex;
  gap: $spacing-xl;

  @media screen and (max-width: $screen-sm) {
    flex-wrap: wrap;
    gap: $spacing-lg;
  }
}

.shareButtonLabel {
  color: $color-gray-700;
}

.facebookIcon {
  color: #1877f2;
}

.twitterIcon {
  color: #1d9bf0;
}

.linkedinIcon {
  color: #0274b3;
}
