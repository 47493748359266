@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.uniqueSharesContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing-sm $spacing-md;
  border: 1px solid $color-gray-300;
  border-radius: $border-sm;
  gap: $spacing-sm;
}

.uniqueSharesTitle {
  font-weight: $weight-bold;
  composes: headerMedium;
}

.uniqueSharesLink {
  line-height: $line-height-lg;
  composes: basicLink;
}
