@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.whyMusicItem {
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: $border-xs;
  gap: $spacing-sm;
  padding-block: $spacing-lg;

  @media screen and (max-width: $screen-md) {
    width: 100%;
  }
}

.whyMusicItemTitle {
  composes: headerLarge;
}
