@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  padding: $spacing-xl;
  gap: $spacing-lg;

  @media screen and (max-width: $screen-sm) {
    justify-content: space-between;
    height: 100%;
    padding: 0;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-sm) {
    justify-content: flex-end;
    height: 270px;
  }
}

.description {
  text-align: center;
  white-space: pre-line;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: $border-full;
  background-color: $color-accent;
}

.icon {
  width: 40px;
  height: 40px;
}
