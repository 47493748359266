@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.royaltyPayoutTable {
  display: flex;
  flex-direction: column;
  composes: unstyledList;
  gap: $spacing-2xs;
  div {
    width: 50%;
    line-height: $line-height-xl;
  }

  @media (max-width: $screen-sm) {
    display: none;
  }
}

.infoIcon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  composes: iconContainer;
  &:hover {
    box-shadow: none;
  }
}

.tableHeaderContainer {
  display: inline-flex;
  flex-direction: row;
  padding-bottom: $spacing-3xs;
  font-weight: $weight-semibold;

  .tableHeader {
    width: 100%;
    text-align: left;
  }
}

.tableRow {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.tableData {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  div {
    display: inline-flex;
    font-size: $font-md;
  }

  .upcomingStatus {
    width: fit-content;
    padding: $spacing-3xs $spacing-2xs;
    border: 1px solid $color-blue;
    border-radius: $border-full;
    background-color: $color-blue-alt;
    line-height: $line-height-md;
    color: $color-blue;
  }
}
