@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.modalContainer {
  width: 100%;
  height: 500px;

  @media screen and (max-width: $screen-sm) {
    height: 100%;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.carousel {
  display: flex;
  margin-top: $spacing-3xl;
  @media screen and (max-width: $screen-sm) {
    margin: auto;
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: $border-full;
  margin-right: $spacing-2xs;
  background-color: $color-gray-300;
  cursor: pointer;
}

.activeDot {
  background-color: $color-primary;
}

.slideContainer {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
  touch-action: none;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column-reverse;
    height: calc(100svh - calc(env(safe-area-inset-top) + env(safe-area-inset-bottom)));
  }
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding: $spacing-md;
  gap: $spacing-sm;
  @media screen and (max-width: $screen-sm) {
    width: 100%;
    padding: $spacing-sm;
  }
}

.closeIcon {
  position: absolute;
  top: $spacing-sm;
  left: $spacing-sm;
  width: 32px;
  height: 32px;
  composes: iconContainer;
  @media screen and (max-width: $screen-sm) {
    & > svg {
      color: white;
    }
    top: $spacing-2xs;
    left: $spacing-2xs;
  }
}

.counter {
  font-size: $font-sm;
  color: $color-gray-500;
}

.slideContent {
  display: flex;
  flex-direction: column;
  min-height: 212px;
  gap: $spacing-2xs;
  @media screen and (max-width: $screen-sm) {
    min-height: 196px;
  }
}

.slideTitle {
  font-size: $font-xl;
  font-weight: $weight-bold;
}

.slideSubtitle {
  font-size: $font-md;
  color: #000;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background-image: url('../../../assets/images/purple-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: $screen-sm) {
    width: 100%;
    height: 50svh;
    flex-grow: 1;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.button {
  width: fit-content;
  margin-block: $spacing-md $spacing-3xl;
  @media screen and (max-width: $screen-sm) {
    width: 100%;
    margin-block: auto;
  }
}

.slideImage {
  width: calc(100% - $spacing-xl);
  height: calc(100% - $spacing-xl);
}
