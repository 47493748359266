@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.statementsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.pageTitle {
  composes: headerLarge;
}

.description {
  max-width: $screen-sm;
  color: $color-gray-500;
}

.downloadForm {
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-md) {
    width: 50%;
  }

  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.inputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: $spacing-sm;
}

.dateInput {
  width: calc(50% - $spacing-2xs);

  &::-webkit-calendar-picker-indicator {
    opacity: 0.5;
    transition: $transition-base;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: $screen-xs) {
    width: 100%;
  }
}

.statementSelector {
  width: 100%;
  text-transform: none;
}

.downloadButton {
  width: calc(50% - $spacing-2xs);
  margin-top: $spacing-sm;

  @media screen and (max-width: $screen-xs) {
    width: 100%;
  }
}
