@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.informationContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
}

.informationTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: $spacing-2xs;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.title {
  composes: headerLarge;

  @media screen and (max-width: $screen-xs) {
    font-size: $font-xl;
  }
}

.subtitle {
  color: $color-gray-500;
  composes: paragraphRegular;
}

.learnMore {
  font-weight: $weight-semibold;
  line-height: $line-height-lg;
  color: $color-primary;
  composes: basicLink;
}
