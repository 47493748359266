@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.cardLimitWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: $spacing-md;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-sm;
  @media screen and (max-width: $screen-sm) {
    margin-top: auto;
  }
}

.title {
  composes: headerLarge;
}

.divider {
  height: 1px;
}
