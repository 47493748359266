@import 'styles/constants.scss';

.selectDocumentTypeContainer {
  gap: 24px;
}

.documentOptions {
  width: 100%;
}

.skip {
  @media screen and (max-width: $screen-sm) {
    margin-top: auto;
  }
}

.description {
  text-align: center;
  color: $color-gray-500;
}
