@import 'styles/constants.scss';

.errorContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: $spacing-lg 0 $spacing-xl 0;
  gap: $spacing-sm;
}

.errorIcon {
  width: 56px;
  height: 56px;
  > * {
    stroke-width: 3;
  }
}

.errorHeading {
  font-size: $font-lg;
  font-weight: $weight-semibold;
  line-height: $line-height-lg;
}

.errorDescription {
  font-size: $font-md;
  font-weight: $weight-medium;
  line-height: $line-height-lg;
  color: $color-gray-500;
}
