@import 'styles/constants.scss';

.availableFundsWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: $spacing-sm;
  hr {
    width: 0.5px;
    height: 100%;
    border: 0;
    background-color: $color-gray-300;
  }

  @media screen and (max-width: $screen-xs) {
    flex-direction: column;
    hr {
      width: 100%;
      height: 0.5px;
    }
  }
}

.fundsContainer {
  width: 100%;
}
