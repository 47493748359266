@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.addNewCard {
  display: flex;
  align-items: center;
  width: fit-content;
  composes: inlineLink;
  gap: $spacing-3xs;

  p {
    font-weight: $weight-medium;
    color: $color-primary;
  }

  .plusIcon {
    transform: none;
    width: 24px;
    height: 24px;
    margin: 0;
    color: $color-primary;
  }
}
