@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.upcomingContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  padding-block: $spacing-lg;

  @media screen and (max-width: $screen-sm) {
    padding-inline: $spacing-sm;
  }
}

.sectionTitle {
  composes: headerLarge;
}

.upcomingSongsContainer {
  display: grid;
  gap: $spacing-xl $spacing-lg;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: $screen-md) {
    grid-template-columns: 1fr;
  }
}
