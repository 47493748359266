@import 'styles/classes/common.module.scss';
@import 'styles/constants.scss';

.tableEmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.tableEmptyStateMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-md;
  gap: $spacing-2xs;
}

.tableEmptyStateMessageText {
  margin-bottom: $spacing-2xs;
  font-size: $font-md;
  color: $color-gray-600;
}

.tableEmptyStateButton {
  font-weight: $weight-semibold;
  color: $color-primary;
  composes: inlineLink;
}
