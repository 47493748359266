@import 'styles/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  gap: $spacing-md;
}

.description {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  text-align: center;
  color: $color-gray-500;
  gap: $spacing-2xs;
}

.logo {
  width: 153px;
  height: 63px;
}
