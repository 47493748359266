@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.bannerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-lg;
  @media screen and (max-width: $screen-md) {
    height: 100%;
    padding: $spacing-lg $spacing-sm;
  }

  &.large {
    min-height: 420px;
    padding: $spacing-2xl $spacing-sm;
  }

  &.small {
    min-height: 280px;
    padding: $spacing-lg $spacing-sm;
  }
}

.title {
  text-align: center;
  font-size: $font-md;
  font-weight: $weight-semibold;
  @media screen and (max-width: $screen-sm) {
    font-size: $font-lg;
    font-weight: $weight-bold;
  }
}

.launchDate {
  text-align: center;
  font-weight: $weight-medium;
  color: $color-primary;
  composes: paragraphRegular;
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.artistLogo {
  max-width: 160px;
  padding: 0 $spacing-sm;
}

.description {
  text-align: center;
  white-space: pre-line;
  color: $color-gray-600;
}
