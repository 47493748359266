@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.royaltyPayoutsModal {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  h1 {
    font-size: $font-xl;
  }
  .songSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacing-sm;
    img {
      width: 64px;
      height: 64px;
      border-radius: $border-xs;
    }
    div {
      h2 {
        font-size: $font-lg;
        font-weight: $weight-medium;
      }
      p {
        font-size: $font-md;
      }
    }
  }
  button {
    margin-top: $spacing-2xs;
    @media screen and (max-width: $screen-sm) {
      position: absolute;
      bottom: $spacing-md;
      width: calc(100% - $spacing-lg);
    }
  }
}

.payoutsList {
  display: flex;
  flex-direction: column;
  composes: unstyledList;
  gap: $spacing-2xs;
  @media screen and (max-width: $screen-sm) {
    overflow-y: scroll;
    height: calc(100vh - 320px);
  }
  li {
    display: flex;
    flex-direction: column;
    padding-bottom: $spacing-2xs;
    border-bottom: 1px solid $color-gray-200;
    gap: $spacing-3xs;
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
    p {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      &:first-child {
        color: $color-gray-500;
      }
      span {
        &:last-child {
          font-weight: $weight-semibold;
        }
      }
    }
  }
}
