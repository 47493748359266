@import 'styles/constants.scss';

.sectionStatus {
  margin-top: $spacing-md;
  color: $color-gray-500;
}

.approved {
  color: $color-green;
}

.refused {
  color: $color-red;
}
