@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.mobileTableContainer {
  display: none;
  flex-direction: column;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-sm) {
    display: flex;
  }
}

.mobileRowWrapper {
  display: flex;
  flex-direction: column;
  color: #000;
  gap: $spacing-sm;

  &.rowClick {
    &:active {
      background-color: $color-gray-200;
    }
  }

  &:last-child {
    padding-bottom: $spacing-md;
    margin-bottom: 0;
    hr {
      display: none;
    }
  }
}

.mobileHeader {
  display: flex;
  position: relative;
  align-items: center;
  gap: $spacing-2xs;
}

.mobileThumbnail {
  width: 24px;
  height: 24px;
  border-radius: $border-xs;
}

.mobileSongInfo {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-left: auto;
  composes: iconContainer;
}

.mobileStatContainer {
  display: flex;
  justify-content: space-between;
  gap: $spacing-2xs;
}

.mobileStatWrapper {
  display: flex;
  flex-direction: column;
}

.mobileStatWrapperFixed {
  width: 25%;
  composes: mobileStatWrapper;
}

.mobileStatTitle {
  font-size: $font-md;
}

.mobileStatValue {
  text-transform: capitalize;
  font-size: $font-md;
  font-weight: $weight-semibold;
  composes: ellipsis;
}
