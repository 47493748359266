@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.confirmationContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.form {
  display: flex;
  flex-direction: column;
  font-size: $font-md;
  flex-grow: 1;
  gap: $spacing-sm;
}

.fundsInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: $weight-normal;
  color: $color-gray-600;
  gap: $spacing-4xs;
}

.value {
  color: $color-gray-600;
}

.totalValue {
  margin-bottom: $spacing-md;
  color: $color-gray-500;
}

.bankAccount {
  justify-content: left;
  color: $color-gray-500;
  composes: value;
  gap: $spacing-2xs;
}

.payOutMethodContainer {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-sm;
}

.payOutMethod {
  display: flex;
  justify-content: space-between;
  font-size: $font-md;
  font-weight: $weight-normal;
}

.edit {
  color: $color-primary-alt;
  composes: inlineLink;
}

.loadingState {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
}

.withdrawButton {
  margin-top: $spacing-md;
  @media screen and (max-width: $screen-sm) {
    margin-top: auto;
  }
}

.disclaimer {
  color: $color-gray-600;
  composes: paragraphMicro;
}
