@import 'styles/constants.scss';

.singedOutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px); // 64px is the height of the navbar
}

.singedOutMessage {
  margin-top: $spacing-sm;
}

.buttonsContainer {
  display: flex;
  margin-top: $spacing-xl;
  gap: $spacing-md;
  @media screen and (max-width: $screen-xs) {
    flex-direction: column;
  }
}
