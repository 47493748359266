@import 'styles/constants.scss';

/* Lists */

.unstyledList {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: $font-md;
  li > a {
    text-decoration: none;
    color: black;
  }
}

/* Layout */

.maxWidthContainer {
  max-width: $max-width;
  padding: 0 $spacing-lg;
  margin: 0 auto;
  @media (max-width: $screen-lg) {
    padding: 0 $spacing-sm;
  }
}

.iconContainer {
  box-sizing: border-box;
  padding: $spacing-3xs;
  border-radius: $border-lg;
  transition: $transition-base;
  cursor: pointer;
  svg {
    width: 100%;
    max-height: 100%;
    color: $color-gray-600;
    transition: $transition-base;
  }
  &:hover {
    background-color: rgb(126, 25, 191, 0.075);
    box-shadow: 0 0 0 2px rgb(126, 25, 191, 0.075);
    svg {
      color: black;
    }
    &:active {
      scale: 0.95;
    }
  }
}

.iconContainerDark {
  composes: iconContainer;
  svg {
    color: $color-gray-200;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    svg {
      color: white;
    }
  }
}

.elevatedUi {
  box-sizing: border-box;
  border: 1px solid $color-gray-200;
  box-shadow: $shadow-md;
}

/* Links and buttons */

.inlineLink {
  display: inline-flex;
  position: relative;
  align-items: center;
  font-size: $font-md;
  font-weight: 500;
  line-height: $line-height-sm;
  transition: $transition-base;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    z-index: $z-index-base - 1;
    top: -$spacing-2xs;
    left: -$spacing-2xs;
    width: calc(100% + $spacing-sm);
    height: calc(100% + $spacing-sm);
    border-radius: $border-xs;
    background-color: transparent;
    transition: $transition-base;
  }
  svg {
    transform: translateX(-2px);
    width: 16px;
    height: 16px;
    margin-left: $spacing-sm;
    transition: $transition-base;
  }
  &:hover {
    color: $color-primary;
    &::before {
      background-color: $color-accent;
    }
    svg {
      transform: translateX(0);
      color: $color-primary;
    }
    &:active {
      scale: 0.95;
    }
  }
}

.inlineLinkColor {
  color: $color-primary;
  composes: inlineLink;
  &:hover {
    color: $color-primary;
  }
}

.basicLink {
  font-size: $font-md;
  font-weight: $weight-medium;
  line-height: $line-height-sm;
  transition: $transition-base;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}

.disabledLink {
  opacity: 0.6;
  font-size: $font-md;
  font-weight: $weight-medium;
  transition: $transition-base;
  cursor: not-allowed;
  pointer-events: none;
}

/* stylelint-disable */
.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* stylelint-enable */

.pill {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: $spacing-2xs $spacing-sm;
  border: 1px solid $color-gray-300;
  border-radius: $border-full;
  background-color: white;
  font-size: $font-md;
  color: $color-gray-500;
  transition: $transition-base;
  cursor: pointer;
  &:hover {
    border: 1px solid $color-accent-alt;
    box-shadow: 0 0 0 2px $color-accent;
    color: $color-primary-alt;
  }
}

/* Input fields */

.clearDefaultInputStyling {
  margin: 0;
  background-color: #fff;
  appearance: none;
}

.baseCheckboxStyling {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid $color-gray-600;
  border-radius: $border-xs;
  transition: $transition-base;
  composes: clearDefaultInputStyling;
  cursor: pointer;
  &::before {
    display: block;
    content: '✓';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    text-align: center;
    font-size: $font-md;
    font-weight: $weight-bold;
    line-height: 100%;
    color: $color-primary;
    transition: $transition-base;
  }
  &:not(:disabled) {
    &:hover {
      border-color: $color-primary;
      &::before {
        opacity: 1;
      }
    }
    &:checked {
      border-color: $color-accent-alt;
      background-color: $color-accent-alt;
      &:hover {
        border-color: $color-primary;
      }
      &::before {
        opacity: 1;
      }
    }
  }
  &:disabled {
    border-color: $color-gray-300;
    cursor: default;
  }
}

.baseInputStyling {
  box-sizing: border-box;
  position: relative;
  width: 284px;
  padding: $spacing-2xs $spacing-sm;
  border: 1px solid $color-gray-300;
  border-radius: $border-xs;
  background-color: white;
  box-shadow: none;
  outline: none;
  font-size: $font-input;
  font-weight: $weight-medium;
  line-height: $line-height-sm;
  transition: $transition-base;
  appearance: none;
  cursor: text;
  &::placeholder {
    color: $color-gray-500;
  }
  &:disabled {
    pointer-events: none;
  }
  &:hover {
    border: 1px solid $color-accent-alt;
    box-shadow: 0 0 0 1px $color-accent;
  }
  &:focus,
  &:active {
    border: 1px solid $color-primary;
  }
  &:has(+ .errorMessage) {
    border: 1px solid $color-red;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }
}

.hamburgerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: $border-full;
  transition: $transition-base;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-200;
    box-shadow: 0 0 0 2px $color-gray-200;

    &:active {
      scale: 0.95;
    }
  }

  &.active {
    svg {
      line:nth-child(1) {
        transform: rotate(45deg) translateY(5px);
        transform-origin: center;
      }
      line:nth-child(2) {
        opacity: 0;
      }
      line:nth-child(3) {
        transform: rotate(-45deg) translateY(-5px);
        transform-origin: center;
      }
    }
  }
}
