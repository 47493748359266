@import 'styles/constants.scss';

.backIcon {
  position: absolute;
  top: $spacing-md;
  left: $spacing-md;
  width: 24px;
  height: 24px;
  cursor: pointer;
  @media screen and (max-width: $screen-md) {
    position: static;
    top: $spacing-md;
    left: calc((100% - $max-width) / 2);
    margin: 0 auto 0 0;
  }
}

.uploadTitle {
  margin-top: $spacing-md;
  @media screen and (max-width: $screen-md) {
    margin-top: 0;
  }
}

.uploadContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-lg 0;
  gap: $spacing-sm;
}

.uploadBoxes {
  display: flex;
  align-items: center;
  width: 100%;
  gap: $spacing-md;
  @media screen and (max-width: $screen-md) {
    flex-direction: column;
  }
}

.documentPage {
  margin-top: $spacing-2xs;
  text-align: center;
  font-size: $font-md;
  font-weight: $weight-normal;
  line-height: $line-height-lg;
  color: $color-gray-600;
}

.guidelines {
  text-align: center;
  font-size: $font-md;
  font-weight: $weight-normal;
  line-height: $line-height-md;
  color: $color-gray-600;
}

.uploadError {
  text-align: center;
  font-size: $font-xs;
  font-weight: $weight-normal;
  color: $color-red;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: $spacing-sm;
}
