@import 'styles/classes/common.module.scss';

.counterContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: $spacing-2xs;
}

.button {
  width: 24px;
  height: 24px;
  padding: 0;
  composes: iconContainer;
  &:hover {
    box-shadow: none;
  }
}

.button.disabled {
  opacity: 0.5;
  &:hover {
    opacity: 0.25;
    cursor: initial;
  }
}

.counterValue {
  min-width: 24px;
  text-align: center;
}

.quantityInput {
  width: 88px;
  height: 40px;
  border: 1px solid $color-gray-300;
  text-align: right;
  composes: baseInputStyling;
}
