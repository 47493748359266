@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    justify-content: space-between;
  }
}

.topWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.songDetails {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.signIn {
  text-align: center;
}

.signInLink {
  font-weight: $weight-semibold;
  color: $color-primary;
  composes: inlineLinkColor;
}

.highlightedText {
  font-weight: $weight-bold;
}
