@import 'styles/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.editCardWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.addCardWrapper {
  display: flex;
  justify-content: space-between;
}
