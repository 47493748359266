@import 'styles/constants.scss';

.metricsCard {
  width: 100%;
  padding: $spacing-sm $spacing-md;
  border-radius: $border-xs;
  background-color: $color-gray-100;
}

.metricTitle {
  font-size: $font-md;
  font-weight: 400;
  color: $color-gray-600;
}

.metricContents {
  display: flex;
  align-items: baseline;
  width: 100%;
  gap: $spacing-2xs;
}

.metricValue {
  font-size: $font-lg;
  font-weight: 600;
}

.metricChange {
  display: flex;
  align-items: center;
  font-size: $font-sm;
  font-weight: 400;
  color: $color-primary;
}
