@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.rewardStep {
  display: flex;
  gap: $spacing-sm;
}

.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: $border-full;
  background-color: $color-accent-alt;
  font-size: $font-sm;
  font-weight: $weight-medium;
  color: black;
}
