@import 'styles/constants.scss';

.liveNow {
  display: flex;
  position: absolute;
  top: $spacing-sm;
  left: $spacing-sm;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: $spacing-4xs $spacing-3xs;
  border-radius: $border-xs;
  background-color: $color-red;
  font-size: $font-xs;
  font-weight: $weight-medium;
  color: white;
  gap: $spacing-3xs;
}

.liveStatus {
  width: 10px;
  height: 10px;
  border-radius: $border-full;
  background-color: white;
}
