@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.tableRow {
  height: 72px;
}

.artist {
  width: 120px;
}

.title {
  width: 120px;
}

.quantity {
  width: 80px;
}

.price {
  width: 100px;
}

.sincePurchase {
  width: 150px;
}

.value {
  width: 90px;
}

.thumbnail {
  display: flex;
  justify-content: center;
  width: 80px;
}

.thumbnailImg {
  width: 40px;
  height: 40px;
  border-radius: $border-xs;
}
