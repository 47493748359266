@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.inviteePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-3xl;
  margin-block: $spacing-md $spacing-xl;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    gap: $spacing-lg;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: $spacing-lg;

  @media screen and (max-width: $screen-md) {
    width: 100%;
    gap: $spacing-md;
  }
}

.title {
  font-size: $font-2xl;
  line-height: $line-height-md;
}

.description {
  margin-top: $spacing-2xs;
  font-size: $font-md;
  color: $color-gray-500;
}

.rewardSteps {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  gap: $spacing-md;

  @media screen and (max-width: $screen-md) {
    width: 100%;
  }
}

.coinStripSm {
  @media screen and (min-width: $screen-sm) {
    display: none;
  }
}

.coinStripLg {
  width: 100%;
  margin-block: $spacing-lg;

  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.sectionTitle {
  text-align: center;
  font-size: $font-2xl;
}

.disclaimer {
  font-size: $font-sm;
  color: $color-gray-500;
}
