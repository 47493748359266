@import 'styles/classes/common.module.scss';
@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.tableContainer {
  width: 100%;
  font-size: $font-md;
  @media screen and (max-width: $screen-md) {
    overflow-x: auto;
  }
}

.flexTable {
  overflow-y: hidden;
  width: 100%;
}

.thead {
  width: 100%;
  text-align: left;
  font-size: $font-xs;
}

.tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 $spacing-md;
  border-top: 1px solid $color-gray-300;
  transition: $transition-base;
  gap: $spacing-sm;

  &.withClick {
    &:hover {
      background-color: $color-gray-200;
      cursor: pointer;
    }
    &:active {
      background-color: $color-primary-light;
    }
  }
}

.thead > .tr {
  height: 56px;
}

.thead > .tr > .th {
  display: flex;
  align-items: center;
  span {
    position: relative;
    font-size: inherit;
    font-weight: $weight-bold;
    color: black;
    transition: $transition-base;
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -24px;
      opacity: 0;
      width: 16px;
      height: 16px;
      background-image: url('../../../../assets/icons/arrow-down.svg');
      transition: $transition-base;
      pointer-events: none;
    }
    &.asc {
      &::after {
        opacity: 1;
      }
    }
    &.dsc {
      &::after {
        opacity: 1;
        transform: rotate(180deg);
      }
    }
  }
}

.tbody {
  width: 100%;
  font-size: inherit;
}

.tbody > .tr {
  &:last-child {
    border-bottom: 0;
  }
}

.td {
  font-size: $font-sm;
  composes: ellipsis;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: $spacing-md;
}

.paginationText {
  height: 24px;
  padding: 0 $spacing-sm;
  font-size: $font-sm;
  line-height: $line-height-lg;
}

.button {
  width: 24px;
  height: 24px;
  composes: iconContainer;
}

.hideInMobile {
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.serverPaginationButton {
  height: 24px;
  color: black;
  gap: $spacing-3xs;

  svg {
    width: 12px;
    height: 12px;
    padding: 0;
    stroke-width: 3;
  }

  p {
    font-size: $font-sm;
    font-weight: $weight-medium;
  }

  &:not(:disabled) {
    &:hover {
      background-color: $color-gray-200;
    }
  }
}

.serverPaginationText {
  min-width: 150px;
  text-align: center;
  font-size: $font-sm;
  color: $color-gray-500;
}

.tableServerPaginatedContainer {
  position: relative;
}

.tableServerPaginatedLoading {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}

.tableServerPaginatedSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
}
