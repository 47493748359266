@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.songInfo {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $screen-sm) {
    gap: $spacing-md;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.infoWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: $spacing-sm;
}

.thumbnail,
.skeleton {
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: $border-xs;
}

.autoScroll {
  padding-inline: $spacing-xs;
}

.subLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.autoScrollWrapper {
  max-width: 70%;
}

.subHeading {
  composes: headerSmall;
}

.artistName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.priceContainer {
  display: flex;
  align-items: center;
  margin-top: $spacing-2xs;
  gap: $spacing-2xs;
}

.spotifyStreams {
  display: flex;
  align-items: center;
  color: $color-gray-500;
  composes: paragraphRegular;
  gap: $spacing-3xs;
}

.spotifyIcon {
  width: 16px;
  height: 16px;
}
