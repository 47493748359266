@import 'styles/constants.scss';

.pageWrapper {
  margin: $spacing-lg 0;
  @media screen and (max-width: $screen-md) {
    margin: $spacing-md 0 $spacing-xl 0;
  }
}

.mastersWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
}

.tabBarContainer {
  margin-bottom: $spacing-md;
  @media screen and (max-width: $screen-sm) {
    padding: 0 $spacing-sm;
  }
}
