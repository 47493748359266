@import 'styles/constants.scss';

.addFunds {
  border-radius: $border-sm;
  box-shadow: $shadow-md;
  gap: 0 $spacing-2xs;
}

.action {
  font-size: $font-xs;
  font-weight: $weight-medium;
  color: black;
}
