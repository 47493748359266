@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.listHeader {
  display: inline-flex;
  flex-direction: row;
  padding-bottom: $spacing-3xs;
  font-weight: $weight-semibold;
}

.listRow {
  display: flex;
  flex-direction: column;
  width: 120px;
}

.listValue {
  display: inline-flex;
  font-size: $font-md;

  &.upcomingStatus {
    width: fit-content;
    padding: $spacing-3xs $spacing-2xs;
    border: 1px solid $color-blue;
    border-radius: $border-full;
    background-color: $color-blue-alt;
    line-height: $line-height-md;
    color: $color-blue;
  }
}
