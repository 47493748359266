@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.modalContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: $spacing-lg;
  @media screen and (max-width: $screen-sm) {
    justify-content: space-between;
  }
}

.topWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
}

.modalTitle {
  font-size: $font-xl;
  composes: headerLarge;
}

.copyToShare {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.showImage {
  display: block;
}

.hideImage {
  display: none;
}

.assetContainer {
  display: flex;
  align-items: center;
  min-height: 360px;
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
}

.copyLabel {
  font-size: $font-sm;
  color: $color-gray-600;
}
