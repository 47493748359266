@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.pageContainer {
  display: flex;
  flex-direction: column;
  padding: 0 $spacing-sm $spacing-3xl;
  margin: $spacing-lg 0;
}

.signUpContainer {
  display: flex;
  margin-top: $spacing-2xl;
  gap: $spacing-xl;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.coinStrip {
  width: 100%;
  padding: 0 $spacing-sm;
  margin: $spacing-4xl 0 $spacing-xl 0;
}
