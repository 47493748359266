@import 'styles/classes/text.module.scss';

.priceGraphHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: $spacing-md $spacing-sm $spacing-sm $spacing-md;
}

.priceInformation {
  display: flex;
  flex-direction: column;
  gap: $spacing-3xs;
}

.title {
  color: $color-gray-600;
}

.subtitle {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: $spacing-3xs;
}

.priceChange {
  height: 40px;
  line-height: $line-height-md;
  color: $color-primary;
  composes: headerLarge;
  @media screen and (max-width: $screen-xs) {
    height: unset;
  }
}

.priceChangePercentage {
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  font-size: $font-sm;
  font-weight: $weight-semibold;
  color: $color-green;
  gap: $spacing-2xs;
  &.red {
    color: $color-red;
  }
}

.graphRange {
  opacity: 1;
  font-weight: $weight-normal;
  color: $color-gray-600;
  transition: $transition-base;
  &.hide {
    opacity: 0;
  }
}

.spinner {
  min-height: 432px;
  border-radius: $border-sm;
  background-color: $color-gray-100;
}
