@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: $spacing-xl;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: $spacing-md;
}

.title {
  text-align: center;
  font-size: $font-xl;
  font-weight: $weight-bold;
}

.subtitle {
  composes: paragraphRegular;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.signIn {
  text-align: center;
}

.signInLink {
  font-weight: $weight-semibold;
  color: $color-primary;
  composes: basicLink;
}
