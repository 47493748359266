@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.commissionCard {
  @media screen and (min-width: $screen-md) {
    order: 1;
  }
}

.commissionHeader {
  display: flex;
  align-items: center;
}

.infoIcon {
  width: 24px;
  height: 24px;
  composes: iconContainer;
  &:hover {
    box-shadow: none;
  }
}

.commissionDropdown {
  margin-left: auto;
}

.dropdownValue {
  font-size: $font-md;
  font-weight: $weight-medium;
  composes: noSelect;
}
