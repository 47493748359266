@import 'styles/classes/common.module.scss';

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-md;
  gap: $spacing-sm;
}

.primaryButtonRed {
  background-color: $color-red;
  &:not(:disabled) {
    &:hover {
      background-color: $color-red-light;
    }
  }
}

.secondaryButtonRed {
  color: $color-red;
  &:not(:disabled) {
    &:hover {
      background-color: $color-red-alt;
    }
  }
}
