@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.documentsContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing-sm $spacing-md;
  border: 1px solid $color-gray-300;
  border-radius: $border-sm;
  gap: $spacing-sm;
}

.title {
  font-weight: $weight-bold;
  composes: headerMedium;
}

.documentsList {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-weight: $weight-medium;
  gap: $spacing-2xs;
}

.documentLink {
  line-height: $line-height-lg;
  composes: basicLink;
}
