@import 'styles/constants.scss';

.tooltip {
  opacity: 0;
  width: 100%;
  font-size: $font-3xs;
  transition: $transition-base;
  transition-property: opacity;
  pointer-events: none;
  &.active {
    opacity: 1;
  }
}

.legendText {
  fill: $color-gray-600;
  font-size: 15px; // rem doesn't work here
}
