.notificationSettingsHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notificationSettingsToggle {
  display: flex;
  justify-content: space-between;
}
