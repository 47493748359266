@import 'styles/constants.scss';

.fullPageLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-top));
  background-color: white;
  gap: $spacing-xl;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - calc(env(safe-area-inset-top) + 64px));
}
