@import '../constants.scss';

/* Typography */

a {
  text-decoration: none;
  font-size: $font-md;
  color: $color-primary;
  transition: $transition-base;
  &:active {
    color: $color-primary;
  }
  &:hover {
    color: $color-primary-alt;
  }
}

b {
  font-size: inherit;
  font-weight: $weight-bold;
}

.headerHuge {
  font-size: $font-2xl;
  font-weight: $weight-light;
  letter-spacing: $line-spacing-sm;
}

.headerLarge {
  font-size: $font-xl;
  font-weight: $weight-bold;
  letter-spacing: $line-spacing-sm;
}

@mixin headerLarge {
  font-size: $font-xl;
  font-weight: $weight-bold;
  letter-spacing: $line-spacing-sm;
}

.headerMedium {
  font-size: $font-lg;
  font-weight: $weight-semibold;
  letter-spacing: $line-spacing-md;
}

@mixin headerMedium {
  font-size: $font-lg;
  font-weight: $weight-semibold;
  letter-spacing: $line-spacing-md;
}

.headerSmall {
  font-size: $font-md;
  font-weight: $weight-semibold;
  letter-spacing: $line-spacing-md;
}

.headerRegular {
  font-size: $font-md;
  font-weight: $weight-medium;
  letter-spacing: $line-spacing-md;
}

p,
.paragraphRegular {
  font-size: $font-md;
  font-weight: $weight-normal;
  letter-spacing: $line-spacing-sm;
}

.paragraphSmall {
  font-size: $font-sm;
  font-weight: $weight-normal;
}

.paragraphMicro {
  font-size: $font-xs;
  font-weight: $weight-normal;
}

.paragraphNano {
  font-size: $font-3xs;
  font-weight: $weight-normal;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Responsive styling */

// Tablet

@media screen and (max-width: $screen-md) {
  .headerLarge {
    font-size: $font-xl;
  }

  .headerMedium {
    font-size: $font-lg;
  }

  .headerSmall {
    font-size: $font-md;
  }
}

// Mobile

@media screen and (max-width: $screen-xs) {
  .headerLarge {
    font-size: $font-lg;
  }

  .headerMedium {
    font-size: $font-md;
  }

  .headerSmall {
    font-size: $font-sm;
  }
}
