@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.container {
  display: flex;
  flex-direction: column;
  overflow: unset !important;
  gap: $spacing-lg;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.title {
  color: $color-gray-700;
  composes: headerLarge;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-4xs;
}

.description {
  color: $color-gray-500;
}
