@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.title {
  font-size: $font-lg;
}

.container {
  display: flex;
  flex-direction: column;
  max-height: 445px;
  padding: $spacing-lg;
  border: 1px $color-gray-300 solid;
  border-radius: $border-xs;
  background-color: white;
  box-shadow: $shadow-md;
  gap: $spacing-sm;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.policyLinkWrapper {
  font-size: $font-xs;
  font-weight: $weight-normal;
  color: $color-gray-600;
}

.policyLink {
  font-size: $font-xs;
  font-weight: $weight-semibold;
  color: $color-gray-600;
}
