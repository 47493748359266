@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-2xs;

  @media screen and (max-width: $screen-2xs) {
    flex-direction: column;
    align-items: flex-start;
  }
}
