// Nomenclature: $<class>-<variant>-<identifier>
// 		- Use constants for properties with only a single argument
// 		- Multi argument CSS properties should use constants for value attribution

// Generics

$max-width: 1088px;

// Color Palette

$color-primary-light: #f2e8f9;
$color-primary: #7e19bf;
$color-primary-alt: #a844e7;
$color-accent: #f2e8f9;
$color-accent-alt: #e5d1f2;
$color-red-light: #f47171;
$color-red: #e85656;
$color-red-alt: #fadddd;
$color-green-light: #68c779;
$color-green: #4da75d;
$color-green-alt: #edf6ef;
$color-blue: #3e54ed;
$color-blue-alt: #e0e4ff;
$color-blue-light: #e9ecff;
$color-off-blue: #e5e8ff;
$color-gray-100: #faf9f6;
$color-gray-200: #f4f3ee;
$color-gray-300: #e0e0db;
$color-gray-400: #c3c2be;
$color-gray-500: #757572;
$color-gray-600: #585755;
$color-gray-700: #353433;
$color-gray-overlay: #3333334d;

// Font sizes

$font-3xs: 1rem; // 8px
$font-2xs: 1.25rem; // 10px
$font-xs: 1.5rem; // 12px
$font-sm: 1.75rem; // 14px
$font-md: 1.875rem; // 15px
$font-lg: 2.25rem; // 18px
$font-xl: 3.75rem; // 30px
$font-2xl: 6.25rem; // 50px
$font-input: 2rem; // 16px

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

$line-height-2xs: 0.5;
$line-height-xs: 0.75;
$line-height-sm: 1;
$line-height-md: 1.25;
$line-height-lg: 1.5;
$line-height-xl: 1.75;
$line-height-2xl: 2;

$line-spacing-sm: 0.01rem;
$line-spacing-md: 0.02rem;
$line-spacing-lg: 0.03rem;

// Spacing

$spacing-4xs: 2px;
$spacing-3xs: 4px;
$spacing-2xs: 8px;
$spacing-xs: 12px;
$spacing-sm: 16px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 48px;
$spacing-2xl: 64px;
$spacing-3xl: 96px;
$spacing-4xl: 128px;

// Shadows

$shadow-xs: 0 0.8px 1.5px rgba(0, 0, 0, 0.06), 0 6px 12px rgba(0, 0, 0, 0.12);
$shadow-sm: 0 0.8px 1.3px rgba(0, 0, 0, 0.032), 0 2.7px 4.5px rgba(0, 0, 0, 0.048),
  0 12px 20px rgba(0, 0, 0, 0.08);
$shadow-md: 0 0 0.6px rgba(0, 0, 0, 0.016), 0 0 1.6px rgba(0, 0, 0, 0.022),
  0 0 3.2px rgba(0, 0, 0, 0.028), 0 0 6.6px rgba(0, 0, 0, 0.034), 0 0 18px rgba(0, 0, 0, 0.05);
$shadow-lg: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);

// Transitions

$transition-base: all 200ms ease-in-out;
$transition-slow: all 400ms ease-in-out;

// Border radius

$border-2xs: 2px;
$border-xs: 4px;
$border-sm: 8px;
$border-md: 12px;
$border-lg: 16px;
$border-xl: 20px;
$border-2xl: 24px;
$border-full: 999px;

// Z-Index

$z-index-transients: -1;
$z-index-base: 0;
$z-index-overlay: 1;
$z-index-navbar: 2;
$z-index-tooltip: 2;
$z-index-backdrop: 3;
$z-index-modal: 4;
$z-index-safe-area: 5; // Always on top of everything

// Screensizes

$screen-2xs: 320px;
$screen-xs: 420px; // ^^ Mobile
$screen-sm: 768px;
$screen-md: 1024px; // ^^ Tablet
$screen-lg: 1280px;
$screen-xl: 1440px;
$screen-2xl: 1920px; // ^^ Desktop

// Width
$empty-state-width: 850px;
