@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.orderFailedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.exclamationIcon {
  width: 48px;
  height: 48px;
  padding: $spacing-3xs;
  svg {
    width: 40px;
    height: 40px;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  composes: headerLarge;
}

.subtitle {
  margin-bottom: $spacing-md;
  color: $color-gray-600;
  composes: paragraphRegular;
}
