@import 'styles/constants.scss';

$overlay-background: rgba(255, 255, 255, 0.6);
$overlay-blur: blur(4px);

.traderOverlay {
  display: none;
  position: fixed;
  z-index: $z-index-overlay;
  top: 0;
  left: 0;
  align-items: flex-end;
  width: 100%;
  height: 100dvh;
  background-color: transparent;
  transition: $transition-slow;
  pointer-events: none;

  @media screen and (max-width: $screen-sm) {
    display: flex;
  }

  &.menuActive {
    background-color: $overlay-background;
    backdrop-filter: $overlay-blur;
    pointer-events: all;
  }
}

.stickyTrader {
  display: none;
  position: fixed;

  z-index: $z-index-overlay;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  width: 100%;
  padding: $spacing-sm;
  padding-bottom: calc($spacing-sm + env(safe-area-inset-bottom));
  background-color: white;
  transition: $transition-slow;
  pointer-events: all;

  &.menuActive {
    background-color: transparent;
  }

  @media screen and (max-width: $screen-sm) {
    display: flex;
  }
}

.priceHeading {
  font-size: $font-md;
}

.amount {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.buttonContainer {
  position: relative;

  &.active {
    .sell {
      transform: translateY(-120px);
    }
    .buy {
      transform: translateY(-60px);
    }
  }
}

.sell {
  position: absolute;
  background-color: $color-red;
}

.buy {
  position: absolute;
}

.trade {
  position: relative;
  z-index: $z-index-overlay;

  .cross {
    height: 28px;
    color: $color-primary;
  }

  &:not(:disabled) {
    &:hover {
      scale: 1;
    }
    &:active {
      scale: 1;
    }
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}
