@import 'styles/classes/text.module.scss';

.pageContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 552px;
  min-height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 72px);
  text-align: center;
}

.header {
  margin-bottom: $spacing-sm;
  composes: headerLarge;
}

.description {
  margin: $spacing-sm 0 $spacing-xl 0;
  color: $color-gray-500;
}
