@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.priceInputWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: $spacing-2xs;
  &.reverseOrder {
    flex-direction: column-reverse;
    align-items: end;
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.priceLabel {
  font-size: $font-lg;
  font-weight: $weight-semibold;
  color: black;
}

.errorMessage {
  opacity: 0;
  color: $color-red;
  transition: $transition-base;
  composes: paragraphMicro;
  &.active {
    opacity: 1;
    transition: $transition-base;
  }
}

.priceInputContainer {
  position: relative;
}

.currencySymbol {
  position: absolute;
  z-index: $z-index-overlay;
  top: 50%;
  left: $spacing-sm;
  transform: translateY(-50%);
  color: $color-gray-500;
  transition: $transition-base;
  pointer-events: none;
  &.highlighted {
    color: black;
  }
}

.currencyInput {
  width: 100%;
  height: 48px;
  padding-left: $spacing-lg;
  composes: baseInputStyling;
}

.error {
  border: 1px solid $color-red !important;
}
