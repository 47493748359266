@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.description {
  margin-bottom: $spacing-lg;
  color: $color-gray-600;
}

.artist {
  width: 150px;
}

.title {
  width: 150px;
}

.id {
  width: 100px;
}

.highestOffer {
  width: 120px;
}

.ends {
  width: 150px;
  color: $color-gray-500;
}

.acceptButton {
  width: 52px;

  @media screen and (max-width: $screen-sm) {
    width: unset;
  }
}

.buttonWrapper {
  color: $color-primary;
  composes: basicLink;

  p {
    font-weight: $weight-medium;
  }
}

.thumbnail {
  display: flex;
  justify-content: right;
  width: 24px;
}

.thumbnailImg {
  width: 24px;
  height: 24px;
  border-radius: $border-xs;
}

.nftImg {
  transform: translateY(-80%) translateX(80%);
  width: 16px;
  border: 2px solid white;
  border-radius: $border-xs;
}
