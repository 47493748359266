@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.royaltyDistributionContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.title {
  display: inline;
  composes: headerLarge;

  @media screen and (max-width: $screen-xs) {
    font-size: $font-xl;
  }
}

.royaltySlider {
  margin-top: $spacing-md;
}

.titleWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: $spacing-2xs;

  @media screen and (max-width: $screen-xs) {
    display: block;
  }
}

.infoIcon {
  min-width: 24px;
  height: 24px;
  composes: iconContainer;

  &:hover {
    box-shadow: none;
  }

  @media screen and (max-width: $screen-xs) {
    display: inline-block;
    margin-left: $spacing-2xs;
  }
}
