@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.title {
  margin-bottom: $spacing-sm;
  text-align: center;
  composes: headerLarge;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.successDescription {
  text-align: center;
  color: $color-gray-500;
  composes: paragraphSmall;
}

.button {
  background-color: $color-primary;
}

.iconWrapper {
  width: 96px;
  height: 96px;
  margin-bottom: $spacing-lg;
}
