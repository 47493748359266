@import 'styles/constants.scss';

.browseSongCard {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

.masterDetailsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.masterInfo {
  position: absolute;
  top: 50%;
  pointer-events: none;
}

.masterOwnerWrapper {
  position: absolute;
  top: $spacing-md;
  pointer-events: none;
}
