@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.imoBriefContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
  &.withPadding {
    padding: $spacing-sm $spacing-md;
    border: 1px solid $color-gray-200;
    border-radius: $border-sm;
  }
}

.imoBriefList {
  display: flex;
  flex-direction: column;
  composes: unstyledList;
  gap: $spacing-md;
}

.listItem {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.header {
  font-size: $font-xl;
  font-weight: $weight-bold;
}

.title {
  font-weight: $weight-bold;
  composes: headerMedium;
}

.subtitle {
  font-size: $font-sm;
  color: $color-gray-600;
}
