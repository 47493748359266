@import 'styles/constants.scss';

.barGraphContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 304px;
  transition: $transition-base;
  touch-action: pan-y;
}

.barGraphSvg {
  display: block;
  width: 100%;
  height: 100%;
}

.barGraph {
  width: 100%;
  height: 100%;
}
