@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.royaltyPayoutList {
  display: none;

  @media (max-width: $screen-sm) {
    display: flex;
    flex-direction: column;
    composes: unstyledList;
    div {
      line-height: $line-height-lg;
    }
  }
}

.infoIcon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  composes: iconContainer;
  &:hover {
    box-shadow: none;
  }
}

.listWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-2xs 0;
  border-bottom: 1px solid $color-gray-300;
  gap: $spacing-2xs;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border: 0;
  }
}
