@import 'styles/constants.scss';

.progressLevel {
  overflow: hidden;
  width: 100%;
  height: 8px;
  border-radius: $border-xs;
  background-color: $color-accent-alt;
}

.filled {
  height: 100%;
  background-color: $color-primary;
  transition: width 0.3s ease;
}

.progressBarContainer {
  display: flex;
  position: relative;
  z-index: $z-index-modal;
  align-items: center;
  width: 512px;
  padding-bottom: $spacing-sm;
  margin-bottom: $spacing-xl;
  gap: $spacing-2xs;
}

@media screen and (max-width: $screen-sm) {
  .progressBarContainer {
    width: 100%;
    margin-bottom: $spacing-md;
  }
}

@media screen and (max-width: $screen-xs) {
  .progressBarContainer {
    width: calc(100% - $spacing-sm);
  }
}
