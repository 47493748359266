@import 'styles/classes/text.module.scss';

.sucessContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.subtitle {
  font-size: $font-lg;
  font-weight: $weight-semibold;
}

.description {
  margin-bottom: $spacing-md;
  font-size: $font-md;
  font-weight: $weight-normal;
  color: $color-gray-600;
}

.doneButton {
  @media screen and (max-width: $screen-sm) {
    margin-top: auto;
  }
}
