@import 'styles/classes/common.module.scss';

.informationList {
  display: flex;
  flex-direction: column;
  composes: unstyledList;
  gap: $spacing-sm;
}

.informationList > li {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  color: $color-gray-600;
}

.informationList > li > span {
  &:nth-child(2) {
    color: $color-gray-600;
  }
}

.informationList > .highlighted > span {
  font-size: $font-lg;
  font-weight: $weight-semibold;
  color: black;
  &:nth-child(2) {
    color: black;
  }
}
