@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.pageTitle {
  composes: headerLarge;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $spacing-xs;

  @media screen and (max-width: $screen-md) {
    justify-content: flex-start;
    margin: $spacing-xs 0;
  }
}

.filtersContainer {
  display: flex;
  margin: $spacing-md 0;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    gap: $spacing-xs;
  }
}

.dateInputContainer {
  display: flex;
  gap: $spacing-xs;

  @media screen and (max-width: $screen-xs) {
    flex-direction: column;
    gap: $spacing-xs;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: $spacing-2xs;

  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.dateInput {
  width: 100%;
  height: 52px;
  composes: baseInputStyling;
}

.date {
  width: 140px;
}

.transactionType {
  width: 140px;
  &::first-letter {
    text-transform: uppercase;
  }
}

.description {
  width: 220px;
}

.tokenId {
  width: 100px;
}

.quantity {
  width: 50px;
}

.amount {
  width: 80px;
}

.fees {
  width: 80px;
}

.total {
  width: 88px;
}

.transactionHistorySpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin: $spacing-sm 0;
}

.emptyStateWithFilters {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-inline: auto;
}

.clearFiltersButton {
  width: 100%;
}
