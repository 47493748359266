@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.tableContainer {
  display: none;
  @media screen and (max-width: $screen-md) {
    display: unset;
  }
}

.rowWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-md;
  color: #000;
  gap: $spacing-sm;

  &:last-child {
    margin: 0;

    hr {
      display: none;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.thumbnail {
  width: 24px;
  height: 24px;
  border-radius: $border-xs;
}

.songInfo {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.buttonWrapper {
  margin-left: auto;
}

.statContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.statWrapper {
  display: flex;
  justify-content: space-between;
}

.statTitle {
  font-size: $font-md;
}

.statValue {
  font-size: $font-md;
  font-weight: $weight-semibold;
}
