@import 'styles/constants.scss';

.lineGraphContainer {
  position: relative;
  width: 100%;
  height: 200px;
  touch-action: pan-y;
  user-select: none;
}

.lineGraphSvg {
  display: block;
  width: 100%;
  height: 100%;
  cursor: default;
}

.lineGraph {
  width: 100%;
  height: 100%;
}

.linePath {
  fill: none;
  stroke-width: 3;
}

.graphLegend {
  position: absolute;
  max-width: 200px;
  text-align: center;
  font-size: $font-xs;
  pointer-events: none;
}

.royalty {
  font-size: $font-md;
  font-weight: $weight-bold;
  color: $color-primary;
}

.legendText {
  font-size: $font-sm;
  color: $color-gray-600;
}

.line {
  stroke: $color-gray-500;
  stroke-width: 1;
}

.crosshair {
  fill: $color-primary;
  cursor: pointer;
  stroke: $color-gray-100;
  stroke-width: 2;
}

.shadow {
  fill: rgba(126, 25, 191, 0.2);

  &.active {
    fill: rgba(126, 25, 191, 0.4);
  }
}

.xAxis {
  path,
  line {
    fill: none;
    stroke: $color-gray-400;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
  text {
    transform: translateY(8px);
    margin-top: $spacing-2xs;
    font-size: $font-md;
    color: $color-gray-600;
  }
}

.xAxisHighlightLine {
  pointer-events: none;
  stroke: $color-primary;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 4;
}

.xAxisLabel {
  position: relative;
  transform: translateY(-30px);
  text-align: center;
  font-size: $font-sm;
  color: $color-gray-600;
  pointer-events: none;
}
