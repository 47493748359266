@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.fullPageMobileDropdownContainer {
  display: none;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  @media screen and (max-width: $screen-sm) {
    display: flex;
    justify-content: flex-end;
    width: 24px;
    height: 24px;
  }
}

.dropdownWrapper {
  display: none;
  position: fixed;
  top: calc(64px + env(safe-area-inset-top));
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  height: 0;
  background-color: #fff;
  transition: $transition-base;

  @media screen and (max-width: $screen-sm) {
    display: flex;
    top: calc(48px + env(safe-area-inset-top));
  }

  &.open {
    height: calc(100vh - calc(env(safe-area-inset-top) + 48px)); // 48px is the height of the navbar
    &.showPreLaunchBanner {
      height: calc(
        100vh - calc(env(safe-area-inset-top) + 48px + 48px)
      ); // 48px is the height of the navbar and the pre-launch banner
    }
  }

  &.showPreLaunchBanner {
    top: calc(
      env(safe-area-inset-top) + 48px + 48px
    ); // 48px is the height of the navbar and the pre-launch banner
  }
}

.dropdownItem {
  padding-block: $spacing-sm;
  a,
  span {
    font-size: $font-md;
    font-weight: $weight-medium;
  }
}

.menuButton {
  color: $color-gray-600;
  composes: hamburgerMenu;
  &:hover {
    color: black;
  }
  svg {
    color: $color-gray-600;
  }
  &.active {
    color: black;
    svg {
      color: black;
    }
  }
}

.dropdownListWrapper {
  position: relative;
  bottom: -100px;
  text-align: center;
  transition: $transition-slow;

  &.itemShown {
    bottom: 0;
  }
}
