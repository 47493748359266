@import 'styles/classes/common.module.scss';

.signInLink {
  font-size: $font-sm;
  composes: basicLink;
}

.disabled {
  composes: disabledLink;
}
