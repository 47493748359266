@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

$thumbnail-size: 40px;

.thumbnailContainer {
  position: relative;
  height: 48px;
}

.thumbnail {
  width: $thumbnail-size;
  min-width: $thumbnail-size;
  border-radius: $border-xs;
}

.nftArt {
  position: absolute;
  top: 20px;
  left: 50%;
  width: 32px;
  height: 32px;
  border: $border-2xs solid white;
  border-radius: $border-xs;
}
