@import 'styles/classes/text.module.scss';

.addCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: $spacing-xs;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.dateAndSecurity {
  display: flex;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-xs) {
    flex-direction: column;
  }
}

.expiryDate {
  display: flex;
  width: 60%;
  gap: $spacing-2xs;

  @media screen and (max-width: $screen-xs) {
    width: 100%;
  }
}

.terms {
  font-size: $font-xs;
  font-weight: $weight-normal;
  color: $color-gray-600;
}

.info {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-sm;
  gap: $spacing-sm;
}

.cardNumberContainer {
  position: relative;
}

.cardIcon {
  position: absolute;
  top: 45%;
  right: 0;
}

.creditCard {
  height: 20px;
  color: $color-gray-400;
}
