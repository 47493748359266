@import 'styles/constants.scss';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerContain {
  overflow: hidden;
  width: 32px;
  height: 32px;
}

.spinnerFill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.spinner {
  width: 32px;
  height: 32px;
  border: 2px solid $color-gray-400;
  border-radius: $border-full;
  animation: spin 1s linear infinite;
}

.primarySpinner {
  border-top: 2px solid $color-primary;
}

.blackSpinner {
  border-top: 2px solid black;
}

.whiteSpinner {
  border-top: 2px solid white;
}
