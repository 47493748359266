@import 'styles/constants.scss';

.reviewOrderContainer {
  display: flex;
  flex-direction: row;
  gap: $spacing-md;
  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
    overflow-y: auto;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 556px;
}

.dividerVertical {
  width: 1px;
  border: 0;
  margin: 0;
  background-color: $color-gray-300;
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.dividerHorizontal {
  height: 1px;
  border: 0;
  margin: 0;
  background-color: $color-gray-300;
}
