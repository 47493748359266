@import 'classes/text.module.scss';
@import 'classes/common.module.scss';
@import 'classes/toast.module.scss';
@import 'styles/constants.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

/* Body, HTML */

html {
  font-size: 8px;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// stylelint-disable-next-line selector-type-no-unknown
ion-header {
  z-index: $z-index-navbar;
}

// Novu
.mantine-Popover-dropdown {
  @media screen and (max-width: $screen-sm) {
    z-index: $z-index-overlay !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100dvh !important;
  }
}

// https://ionicframework.com/docs/theming/color-generator
:root {
  --ion-font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  --ion-color-primary: $color-primary;
  --ion-color-primary-rgb: 126, 25, 191;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #6f16a8;
  --ion-color-primary-tint: #8b30c5;

  --ion-color-secondary: $color-primary-alt;
  --ion-color-secondary-rgb: 168, 68, 231;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #943ccb;
  --ion-color-secondary-tint: #b157e9;

  --ion-color-tertiary: $color-blue;
  --ion-color-tertiary-rgb: 62, 84, 237;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #374ad1;
  --ion-color-tertiary-tint: #5165ef;

  --ion-color-success: $color-green;
  --ion-color-success-rgb: 77, 167, 93;
  --ion-color-success-contrast: #000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #449352;
  --ion-color-success-tint: #5fb06d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: $color-red;
  --ion-color-danger-rgb: 232, 86, 86;
  --ion-color-danger-contrast: #000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #cc4c4c;
  --ion-color-danger-tint: #ea6767;

  --ion-color-medium: $color-gray-400;
  --ion-color-medium-rgb: 195, 194, 190;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #acaba7;
  --ion-color-medium-tint: #c9c8c5;

  --ion-color-light: $color-gray-100;
  --ion-color-light-rgb: 250, 249, 246;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdbd8;
  --ion-color-light-tint: #fbfaf7;
}
