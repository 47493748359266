@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.accordionHeaderWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.accordionTitle {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.accordionItem {
  border-radius: $border-sm;
}

.accordionContent {
  padding: $spacing-2xs 0 $spacing-sm 0;
}

.rowCount {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  padding: 0 $spacing-2xs;
  border-radius: $border-full;
  background-color: $color-primary-light;
  color: $color-primary;
  p {
    font-size: $font-sm;
    font-weight: $weight-semibold;
  }
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.tableHeader > p {
  font-weight: $weight-bold;
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: $color-gray-600;
  gap: $spacing-2xs;
}

.tableRow > p {
  font-size: $font-sm;
}

.type {
  width: 25%;
  composes: ellipsis;
}

.qty {
  width: 12%;
  min-width: 30px;
}

.price {
  width: 15%;
}

.id {
  width: 20%;
  composes: ellipsis;
}

.total {
  width: 18%;
  min-width: 70px;
}

.desktopOnlyRow {
  @media screen and (max-width: $screen-md) {
    display: none;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 55px;
}

.button {
  color: $color-primary;
  composes: basicLink;
}
