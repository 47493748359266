@import 'styles/constants.scss';

.maintenanceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $spacing-xl 0;
  gap: $spacing-sm;
}

.illustration {
  width: 100%;
  max-width: 800px;
  height: fit-content;
}

.heading {
  text-align: center;
  font-size: $font-lg;
  font-weight: $weight-bold;
}

.subHeading {
  text-align: center;
  font-size: $font-sm;
}
