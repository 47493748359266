@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $spacing-md;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.title {
  font-size: $font-xl;
  font-weight: $weight-bold;
  line-height: $line-height-sm;
}

.subtitle {
  font-size: medium;
  color: $color-gray-600;
  composes: paragraphMicro;
}

.songInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: $spacing-2xs;
}

.songTitle {
  line-height: $line-height-xl;
  composes: headerSmall;
}

.artistName {
  color: $color-gray-600;
}

.albumCover {
  width: 48px;
  height: 48px;
  border-radius: $border-xs;
}
