@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.container {
  display: flex;
  padding: $spacing-xs;
  border: $border-2xs solid $color-primary;
  border-radius: $border-xs;
  margin-top: $spacing-sm;
}

.formContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-sm;
}
