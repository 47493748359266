@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.orderTypesContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.heading {
  font-size: $font-lg;
}

.emptyState {
  color: $color-gray-600;
}

.listContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 70vh;
  gap: $spacing-sm;
}

.fractionWrapper {
  display: flex;
  align-items: center;
  padding: $spacing-sm;
  border-radius: $border-sm;
  background-color: $color-gray-200;
  gap: $spacing-sm;
}

.thumbnailImg {
  width: 40px;
  height: 40px;
  border-radius: $border-xs;
}

.title {
  font-size: $font-sm;
}

.id {
  font-size: $font-sm;
  font-weight: $weight-medium;
}

.price {
  margin-left: auto;
  font-size: $font-sm;
  font-weight: $weight-medium;
}
