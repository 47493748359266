@import 'styles/constants.scss';

.yield {
  display: flex;
  flex: 0 1 auto;
  font-size: $font-sm;
  font-weight: $weight-normal;
  color: $color-gray-500;
  gap: $spacing-4xs;
}

.description {
  white-space: nowrap;
}
