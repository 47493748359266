@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.overviewContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xl;
}

.tableGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.metrics {
  display: grid;
  gap: $spacing-sm;
  grid-template-columns: 40% 40% auto;

  @media screen and (max-width: $screen-md) {
    grid-template-columns: 49% 49%;
  }

  @media screen and (max-width: $screen-sm) {
    grid-template-columns: auto;
  }
}

.heading {
  font-size: $font-xl;
  font-weight: $weight-bold;
}

.mobileTradingFeesCard {
  display: none;
  @media screen and (max-width: $screen-md) {
    display: block;
  }
}

.desktopTradingFeesCard {
  display: block;
  @media screen and (max-width: $screen-md) {
    display: none;
  }
}

.addFundsButton {
  flex-direction: column;
}
