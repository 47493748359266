@import 'styles/classes/common.module.scss';
@import 'styles/constants.scss';

.refresher {
  top: env(safe-area-inset-top);
}

.wrapper {
  overflow-x: hidden;
  width: 100%;
  min-height: 100dvh;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.container {
  composes: maxWidthContainer;
  @media screen and (max-width: $screen-sm) {
    padding-inline: $spacing-sm;
  }
}

.contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: $spacing-md 0;
  margin: $spacing-2xl auto $spacing-md auto;

  @media screen and (max-width: $screen-sm) {
    justify-content: left;
    height: 100%;
    min-height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    margin: 0;
  }
}

.gradientBall {
  position: fixed;
  z-index: $z-index-transients;
  top: $spacing-xl;
  left: calc(50% - $max-width/2 + $spacing-2xl);

  @media screen and (max-width: $max-width) {
    left: calc((100% - 100vw) / 2);
  }

  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.logo {
  position: fixed;
  top: $spacing-md;
  left: calc((100% - $max-width) / 2);
  height: 48px;
  padding-left: $spacing-lg;

  @media screen and (max-width: $max-width) {
    left: calc((100% - 100vw) / 2);
  }

  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.logoMini {
  position: static;
  top: $spacing-md;
  left: calc((100% - $max-width) / 2);
  margin: 0 auto $spacing-md 0;

  @media screen and (min-width: $screen-sm) {
    display: none;
  }
}

.safeArea {
  position: fixed;
  z-index: $z-index-safe-area;
  top: 0;
  left: 0;
  width: 100%;
  height: env(safe-area-inset-top);
  background-color: white;
}
