.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoPlayer,
.videoPlayerPlaceholder {
  display: flex;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  object-fit: cover;
  object-position: center center;
}

.videoPlayerPlaceholder {
  position: absolute;
}

.long {
  height: 624px;
}

.short {
  height: 100%;
}

.fluid {
  height: auto;
}

.hidden {
  display: none;
}

.visible {
  display: flex;
}
