@import 'styles/constants.scss';

.modalContent {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 48px); /* Subtracting space taken by close button */

  /* Shadow covers */
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;

  @media screen and (max-width: $screen-sm) {
    background: none;
  }
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
