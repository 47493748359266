@import 'styles/constants.scss';

.traderContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.title {
  font-size: $font-xl;
}

.masterInfo {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.thumbnail {
  height: 48px;
  border-radius: $border-xs;
}

.masterName {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.artistName {
  font-weight: $weight-medium;
  color: $color-gray-600;
}
