@import 'styles/constants.scss';

.trustPilotBoxWrapper {
  transform: translateX(-36px);
  overflow: hidden;
  height: 20px;

  &.centered {
    transform: unset;
  }

  @media screen and (max-width: $screen-md) {
    transform: unset;
  }
}
