@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.imoCard {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: $spacing-sm;
}

.masterDetailsWrapper {
  @media screen and (max-width: $screen-sm) {
    padding: 0 $spacing-sm;
  }
}

.masterInfo {
  position: absolute;
  top: 50%;
  pointer-events: none;
}

.countdownWrapper {
  position: absolute;
  bottom: 0;

  @media screen and (max-width: $screen-sm) {
    width: 264px;
  }
}

.masterOwnerWrapper {
  position: absolute;
  top: $spacing-md;
}
