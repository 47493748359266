@import 'styles/constants.scss';

.selectedSharesCount {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-full;
  background-color: white;
  font-size: $font-xs;
  color: $color-primary;
}

.reviewOrderButton {
  margin-left: auto;

  @media screen and (max-width: $screen-sm) {
    margin-left: unset;
  }
}
