@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-sm;
}

.title {
  color: $color-gray-700;
  composes: headerLarge;
  @media screen and (max-width: $screen-sm) {
    margin-bottom: $spacing-md;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-lg;
  gap: $spacing-2xs;
}
