@import 'styles/constants.scss';

.step {
  display: flex;
  gap: $spacing-sm;
}

.stepNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: 1px solid $color-gray-600;
  border-radius: 50%;
  font-size: $font-xs;
}
