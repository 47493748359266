@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.overviewContainer {
  display: flex;
  margin-bottom: $spacing-2xl;
  gap: $spacing-md;
  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 664px; // 1024 (masterContainer) - 336 (sidebarContainer) - 24 (gap)
  gap: $spacing-2xl;
  @media screen and (max-width: $screen-md) {
    width: 60%;
  }
  @media screen and (max-width: $screen-sm) {
    width: 100%;
    gap: $spacing-lg;
  }
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  width: 336px; // 1024 (masterContainer) - 664 (sidebarContainer) - 24 (gap)
  min-width: 336px;
  gap: $spacing-md;
  @media screen and (max-width: $screen-md) {
    width: 40%;
  }
}
