@import 'styles/constants.scss';

.accordionItem {
  overflow: hidden;
  padding: 0 $spacing-md;
  border-radius: $border-xs;
  box-shadow: $shadow-md;
}

.btnReset {
  all: unset;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  font-size: $font-md;
  font-weight: $weight-semibold;
  cursor: pointer;
}

.accordionContent {
  font-size: $font-sm;
}

.accordionContent[data-state='open'] {
  animation: slideDown 200ms ease-in-out forwards;
}

.accordionContent[data-state='closed'] {
  animation: slideUp 200ms ease-in-out forwards;
}

.accordionChevron {
  width: 16px;
  color: $color-gray-500;
  transition: $transition-base;
}

.accordionHeader[data-state='open'] > .accordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
