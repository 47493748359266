@import 'styles/constants.scss';

.pill {
  width: fit-content;
  padding: $spacing-4xs $spacing-2xs;
  border-radius: $border-full;
  font-size: $font-xs;
}

.primary {
  background-color: $color-primary-light;
  color: $color-primary;
}

.info {
  background-color: $color-blue-light;
  color: $color-blue;
}
