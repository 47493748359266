@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: $spacing-lg 0;
}

.tableGroupWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.tabBar {
  margin-bottom: $spacing-md;
}

.metrics {
  display: grid;
  gap: $spacing-sm;
  grid-template-columns: 40% 40% auto;

  @media screen and (max-width: $screen-md) {
    grid-template-columns: 49% 49%;
  }

  @media screen and (max-width: $screen-sm) {
    grid-template-columns: auto;
  }
}

.heading {
  font-size: $font-xl;
  font-weight: $weight-bold;
}

.mobileTradingFeesCard {
  display: none;
  @media screen and (max-width: $screen-md) {
    display: block;
  }
}

.desktopTradingFeesCard {
  display: block;
  @media screen and (max-width: $screen-md) {
    display: none;
  }
}
