@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-sm;
}

.title {
  margin-bottom: $spacing-md;
  text-align: center;
  color: $color-gray-700;
  composes: headerLarge;
  @media screen and (max-width: $screen-sm) {
    margin-bottom: $spacing-md;
  }
}

.description {
  align-self: flex-start;
  width: 100%;
  max-width: 350px;
  margin: 0 auto $spacing-lg;
  text-align: center;
  color: $color-gray-500;
}

.footnote {
  margin: $spacing-sm 0;
  font-size: $font-xs;
  color: $color-gray-500;
  @media screen and (max-width: $screen-sm) {
    text-align: center;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-sm;
}

.hyperlink {
  font-size: $font-xs;
  font-weight: 700;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.divider {
  transform: translateY(-4px);
  width: 100%;
  border: 1px solid $color-gray-200;
  margin-bottom: $spacing-md;
}

.buttonWrapper {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: $spacing-md;
}

.onboardingContainer {
  @media screen and (max-width: $screen-sm) {
    width: 100%;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
}

.dobContainer {
  display: flex;
  align-items: flex-end;
  gap: $spacing-sm;
}

.errorMessage {
  font-size: $font-xs;
  line-height: $font-xs;
  color: $color-red;
}

.submitButton {
  gap: $spacing-sm;
}

.iconSpacing {
  margin-top: $spacing-2xs;
}

.supportedCountriesLink {
  font-size: $font-sm;
  composes: basicLink;
}

.countryOfResidence {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.footerWrapper {
  width: 100%;
  margin-top: $spacing-4xl;
}

.footerText {
  max-width: 500px;
  text-align: center;
  color: $color-gray-500;
}
