@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.streamsGraphContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.title {
  composes: headerLarge;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.infoIcon {
  width: 24px;
  height: 24px;
  composes: iconContainer;
  &:hover {
    box-shadow: none;
  }
}
