@import 'styles/classes/text.module.scss';

.pageContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 552px;
  min-height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 72px);
  text-align: center;
}

.header {
  margin-bottom: $spacing-sm;
  composes: headerLarge;
}

.iconSpacing {
  margin-top: $spacing-2xs;
}

.title {
  margin-bottom: $spacing-md;
  text-align: center;
  color: $color-gray-700;
  composes: headerLarge;
  @media screen and (max-width: $screen-sm) {
    margin-bottom: $spacing-md;
  }
}

.description {
  width: 100%;
  max-width: 410px;
  text-align: center;
  color: $color-gray-500;
}

.resendPasswordContainer {
  display: flex;
  flex-direction: column;
  margin: $spacing-lg 0;
  gap: $spacing-2xs;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  font-weight: $weight-semibold;
  color: $color-primary;
}
