@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.masterMetadataContainer {
  margin-bottom: $spacing-md;
}

.masterMetadata {
  font-size: $font-md;
  font-weight: 400;
  color: $color-gray-600;
}

.metadataValue {
  font-weight: 600;
  color: black;
}

.polygonScanLink {
  font-weight: 600;
  color: black;
  composes: basicLink;
}

.documentLink {
  margin-top: $spacing-2xs;
  composes: basicLink;
}

.masterMetadataText {
  margin-bottom: $spacing-sm;
}

.documentIcon {
  margin-right: $spacing-2xs;
}

.displayNone {
  display: none;
}
