@import 'styles/constants.scss';

.reviewImoModal {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.continueButton {
  @media screen and (max-width: $screen-md) {
    margin-top: auto;
  }
}
