@import 'styles/classes/common.module.scss';

.announcementContainer {
  background: black;
}

.infoIcon {
  width: 20px;
  height: 20px;
}
