@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.tabBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  composes: unstyledList;
  composes: noSelect;
  gap: $spacing-sm;
}

.tab {
  line-height: $line-height-sm;
  transition: $transition-base;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.border {
  &.tabBar {
    border-bottom: 1px solid $color-gray-300;
  }

  .tab {
    padding: $spacing-2xs;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    font-size: $font-md;
    color: $color-gray-600;
  }

  .active {
    border-bottom: 2px solid $color-primary;
    color: $color-primary;
  }
}

.fill {
  gap: $spacing-2xs;

  .tab {
    padding: $spacing-2xs $spacing-sm;
    border-radius: $border-xs;
    color: black;
  }

  .active {
    background-color: $color-primary;
    color: white;

    &:hover {
      opacity: 1;
    }
  }
}
