@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.footerContainer {
  width: 100%;

  &.withStickyTrader {
    @media screen and (max-width: $screen-sm) {
      padding-bottom: $spacing-2xl;
    }
  }
}

.footerWrapper {
  width: 100%;
  max-width: $max-width;
  padding: $spacing-4xl $spacing-lg $spacing-3xl $spacing-lg;
  margin: 0 auto;

  @media screen and (max-width: $screen-sm) {
    padding: $spacing-xl;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    align-items: center;
    gap: $spacing-2xl;
  }
}

.logo {
  width: 170px;
  height: 63px;
}

.address {
  text-align: left;
  white-space: pre-line;
  margin-block: $spacing-sm;

  @media screen and (max-width: $screen-md) {
    text-align: center;
  }
}

.leftWrapper {
  @media screen and (max-width: $screen-md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.rightWrapper {
  display: flex;
  gap: $spacing-4xl;

  @media screen and (max-width: $screen-sm) {
    flex-wrap: wrap;
    gap: $spacing-xl;
  }
  @media screen and (max-width: $screen-xs) {
    flex-direction: column;
    align-items: center;
  }
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-3xs;

  @media screen and (max-width: $screen-xs) {
    align-items: center;
  }
}

.listHeading {
  margin-bottom: $spacing-2xs;
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.link {
  font-size: $font-sm;
  color: $color-gray-600;
}

.mangoPayLogo {
  margin-top: $spacing-2xs;
}

.divider {
  margin: $spacing-md 0;

  @media screen and (max-width: $screen-xs) {
    margin: $spacing-xl 0;
  }
}

.bottomContainer {
  display: grid;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-lg;
  grid-template-columns: 2fr 0.8fr 0.5fr;

  @media screen and (max-width: $screen-sm) {
    gap: $spacing-2xs;
    grid-template-columns: 1fr;
  }
}

.copyright {
  @media screen and (max-width: $screen-sm) {
    order: 3;
    text-align: center;
  }
}

.mobileAppLinksContainer {
  display: flex;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-sm) {
    order: 1;
    justify-content: center;
    margin-bottom: $spacing-md;
  }
}

.socialsContainer {
  display: flex;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-sm) {
    order: 2;
    justify-content: center;
  }
}

.socialIcon {
  width: 24px;
  height: 24px;
  color: black;
  transition: $transition-base;

  &:hover {
    color: $color-primary-alt;
  }
}
