@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.hero {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: $border-sm;
  box-shadow: $shadow-lg;

  &:hover {
    .overlay {
      background-color: $color-gray-overlay;
    }
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: $transition-slow;
  padding-inline: $spacing-2xs;
  pointer-events: none;
}

.large {
  aspect-ratio: 21/9;

  @media screen and (min-width: $screen-xs) and (max-width: $screen-sm) {
    border-radius: 0;
    aspect-ratio: 16/9;
  }

  @media screen and (max-width: $screen-xs) {
    border-radius: 0;
    aspect-ratio: 3 / 3.5;
  }
}

.small {
  height: 270px;
}
