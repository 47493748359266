@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.songInfo {
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $screen-sm) {
    gap: $spacing-md;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.infoWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: $spacing-sm;
}

.thumbnail,
.skeleton {
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: $border-xs;
}

.autoScroll {
  padding-inline: $spacing-2xs;
}

.subLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.autoScrollWrapper {
  max-width: 70%;
}

.subHeading {
  font-size: $font-lg;
  font-weight: $weight-semibold;
}

.artistName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hidden {
  display: none;
}

.visible {
  display: flex;
}
