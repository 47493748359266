@import 'styles/constants.scss';

.positive {
  color: $color-green;
}

.negative {
  color: $color-red;
}

.neutral {
  color: $color-gray-600;
}

.priceChange {
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.percentageChangeDesktop {
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.percentageChangeMobile {
  display: none;
  @media screen and (max-width: $screen-sm) {
    display: unset;
  }
}
