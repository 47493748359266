@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.reviewKycContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $spacing-lg;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    justify-content: flex-end;
    height: 55%;
  }
}

.title {
  text-align: center;
  composes: headerLarge;
}

.supportText,
.subtitle {
  text-align: center;
  font-size: $font-md;
  font-weight: $weight-normal;
  line-height: $line-height-lg;
  color: $color-gray-600;
}

.footnote {
  display: flex;
  flex-direction: column;
  composes: subtitle;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}
