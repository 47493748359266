@import 'styles/constants.scss';

.rangeSelectorContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}

.rangeSelector {
  box-sizing: border-box;
  height: 100%;
  padding: $spacing-3xs $spacing-lg $spacing-3xs $spacing-sm;
  border: 0;
  border-radius: $border-xs;
  background-color: $color-gray-100;
  box-shadow: none;
  outline: none;
  font-size: $font-md;
  font-weight: $weight-semibold;
  color: $color-primary;
  transition: $transition-base;
  appearance: none;
  cursor: pointer;
  &:hover {
    background-color: $color-gray-200;
    box-shadow: 0 0 0 1px $color-gray-200;
  }
  &:focus {
    background-color: $color-gray-200;
    box-shadow: 0 0 0 1px $color-gray-300;
  }
}

.dropdownIcon {
  position: absolute;
  top: 50%;
  right: $spacing-2xs;
  transform: translateY(-50%);
  width: 20px;
  height: 100%;
  color: $color-primary;
  pointer-events: none;
}
