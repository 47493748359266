@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.orderbookContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing-sm $spacing-md;
  border: 1px solid $color-gray-300;
  border-radius: $border-sm;
  gap: $spacing-sm;
}

.title {
  font-weight: $weight-bold;
  composes: headerMedium;
}

.tableContainer {
  display: flex;
  gap: $spacing-sm;
}

.bidTable,
.askTable {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: $spacing-2xs;
}

.bidTable .amount {
  color: $color-primary;
}

.askTable .amount {
  color: $color-red;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}

.tableHeading {
  font-size: $font-md;
  font-weight: $weight-semibold;
  color: $color-gray-500;
}

.tableRowContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.tableRowWrapper {
  display: flex;
  justify-content: space-between;
}

.size,
.amount {
  font-size: $font-sm;
}

.spinner {
  height: 170px;
}
