@import 'styles/constants.scss';

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: $spacing-sm;
  background-color: white;
  gap: $spacing-xl;
}
