@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.headerWithoutTabs {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $color-gray-200;
  composes: noSelect;
}

.title {
  position: relative;
  padding: $spacing-sm;
  text-align: center;
  color: $color-primary;
  transition: $transition-base;
  composes: headerMedium;
}

.tabBarContainer {
  padding: $spacing-3xs $spacing-md 0 $spacing-md;

  .tab {
    padding: $spacing-sm;
    font-size: $font-lg;
    font-weight: $weight-semibold;
  }

  .sell {
    border-color: $color-red;
    color: $color-red;
  }
}
