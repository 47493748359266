@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.artist {
  width: 120px;
}

.title {
  width: 100px;
}

.description {
  margin-bottom: $spacing-lg;
  color: $color-gray-600;
}

.id {
  width: 50px;
}

.yourOffer {
  width: 90px;
}

.highestOffer {
  width: 120px;
}

.ends {
  width: 100px;
  color: $color-gray-500;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  composes: iconContainer;
}

.cancelOffer {
  width: 40px;
  height: 40px;
}

.thumbnail {
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 24px;
}

.thumbnailImg {
  width: 24px;
  height: 24px;
  border-radius: $border-xs;
}

.nftImg {
  transform: translateY(-80%) translateX(80%);
  width: 16px;
  border: 2px solid white;
  border-radius: $border-xs;
}
