@import 'styles/constants.scss';

.title {
  font-size: $font-lg;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $spacing-md $spacing-2xl;
  border-radius: $border-xs;
  background-color: $color-gray-100;

  @media screen and (max-width: $screen-md) {
    padding: $spacing-md $spacing-sm;
  }
}

.listWrapper {
  padding: $spacing-sm;
}

.listItem {
  font-size: $font-md;
  font-style: italic;
  line-height: $line-height-lg;
  color: $color-gray-600;

  @media screen and (max-width: $screen-md) {
    font-size: $font-sm;
  }
}
