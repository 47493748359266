@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.container {
  display: flex;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 350px;
  min-width: 350px;
  height: min-content;
  border: 1px solid $color-gray-500;
}

.liveTag {
  position: absolute;
  z-index: $z-index-overlay;
  top: 10px;
  left: 10px;
}

.masterDetailsContainer {
  padding: $spacing-sm;
}

.masterAsset {
  width: 100%;
  height: 260px;
  object-fit: cover;
}

.masterName {
  font-size: $font-lg;
}

.artistName {
  font-size: $font-input;
}

.imoDetailsContainer {
  display: flex;
  flex-direction: column;
  height: min-content;
  padding: $spacing-sm;
  border-top: 1px solid $color-gray-500;
  gap: $spacing-2xs;
}

.imoHeading {
  font-size: $font-xs;
  font-weight: $weight-bold;
}

.imoMetrics {
  color: $color-gray-500;
}
