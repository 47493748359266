@import 'styles/constants.scss';

.Toastify__close-button {
  display: none !important;
}

.Toastify__toast-container {
  padding-top: $spacing-xl !important;

  &.Toastify__toast-container--bottom-center {
    @media screen and (max-width: $screen-md) {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      gap: $spacing-2xs;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: fit-content !important;
  }

  @media screen and (max-width: $screen-md) {
    display: flex !important;
    justify-content: center !important;
    padding-bottom: $spacing-xl !important;
  }
}

.Toastify__toast {
  height: 40px !important;
  min-height: 32px !important;
  padding: 0 !important;
}

.Toastify__toast-body {
  width: fit-content !important;
  margin: 0 !important;
  font-size: $font-sm;
  color: #000 !important;
}

.Toastify__toast {
  width: fit-content !important;
  padding: $spacing-sm $spacing-md !important;
}
