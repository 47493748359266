@import 'styles/constants.scss';

.barPlot {
  rect {
    border-radius: $border-xs 0 0 $border-xs;
    fill: $color-primary;
    stroke: $color-primary;
    stroke-width: 2;
  }
}
