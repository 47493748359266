@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.avatarContainer {
  display: flex;
  gap: $spacing-2xs;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
