@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.orderFailedContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    justify-content: flex-end;
    height: 50%;
  }
}

.exclamationIcon {
  width: 48px;
  height: 48px;
  padding: $spacing-3xs;
  svg {
    width: 40px;
    height: 40px;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.subtitle {
  text-align: center;
  color: $color-gray-600;
  composes: paragraphRegular;
}

.returnButton {
  margin-top: $spacing-md;
}
