@import 'styles/constants.scss';

.container {
  width: 100%;
}

.iframe {
  display: none;
  width: 100%;
  min-height: 424px;
  border: 0;
  margin-bottom: $spacing-sm;
  appearance: none;
  @media screen and (max-width: $screen-sm) {
    margin: 0;
  }
}

.showIframe {
  display: block;
}
