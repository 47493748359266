@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.recentTradesContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing-sm $spacing-md;
  border: 1px solid $color-gray-300;
  border-radius: $border-sm;
  gap: $spacing-sm;
}

.title {
  font-weight: $weight-bold;
  composes: headerMedium;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.tableRow {
  display: flex;
}

.date,
.quantity,
.price {
  font-size: $font-sm;
  color: $color-gray-600;
  flex-basis: 0;
  flex-grow: 1;
}

.date {
  flex-grow: 1.4;
}

.tableHeader {
  display: flex;
  p {
    font-weight: $weight-semibold;
    color: $color-gray-500;
  }
}

.spinner {
  height: 150px;
}
