@import 'styles/classes/text.module.scss';

.insufficientFundsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.highlightedText {
  font-weight: $weight-semibold;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: $spacing-md;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
