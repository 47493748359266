@import 'styles/classes/text.module.scss';

.heading {
  margin-bottom: $spacing-sm;
  composes: headerLarge;
  @media screen and (max-width: $screen-xs) {
    font-size: $font-xl;
  }
}

.description {
  color: $color-gray-500;
  composes: paragraphRegular;
}
