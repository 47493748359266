@import 'styles/constants.scss';

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $spacing-sm;
  border-radius: 100%;
  margin-bottom: $spacing-sm;
  background-color: $color-accent;
  svg {
    width: 32px;
    height: 32px;
    color: $color-primary;
    stroke-width: 2px;
  }
}
