@import 'styles/classes/text.module.scss';

.methodContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: $spacing-sm 0;
  border-radius: $border-sm;
  transition: $transition-base;
  cursor: pointer;
  gap: $spacing-md;
  &:hover {
    background-color: $color-gray-100;
    &::after {
      opacity: 0.6;
    }
  }
}

.methodIcon {
  width: 40px;
  height: 40px;
  color: $color-gray-400;
}

.methodIcon > svg {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: $spacing-sm;
}

.methodContent {
  flex: 0 1 calc(100% - $spacing-xl);
  padding-right: $spacing-xl;
}

.methodTitle {
  composes: headerSmall;
}

.methodDescription {
  line-height: $line-height-md;
  color: $color-gray-500;
  composes: paragraphSmall;
}

.pill {
  margin-top: $spacing-2xs;
}

.chevron {
  height: 16px;
  color: $color-primary;
  stroke-width: 3;
}
