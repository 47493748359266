@import 'styles/classes/common.module.scss';

.tableRow {
  justify-content: flex-start;
  height: 72px;
}

.headerRow {
  justify-content: flex-start;
}

.masterName {
  width: 150px;
}

.artistName {
  width: 200px;
}

.amount {
  width: 150px;
}

.thumbnail {
  display: flex;
  justify-content: center;
  width: 80px;
}

.thumbnailImg {
  width: 40px;
  height: 40px;
  border-radius: $border-xs;
}
