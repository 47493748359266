@import 'styles/constants.scss';

.sectionTitle {
  font-size: $font-2xl;
}

.whyMusicContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whyMusicList {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
    gap: $spacing-lg;
  }
}
