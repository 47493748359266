@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.infoIcon {
  width: 24px;
  height: 24px;
  composes: iconContainer;
  &:hover {
    box-shadow: none;
  }
}

.container {
  flex: 0 1 33.33%;
  @media screen and (max-width: $screen-md) {
    flex: 0 1 50%;
  }
}

.titleContainer {
  display: flex;
  position: relative;
  gap: $spacing-4xs;
}

.title {
  max-width: 200px;
  margin-bottom: $spacing-3xs;
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.value {
  font-size: $font-md;
}

.timePeriod {
  margin-bottom: $spacing-3xs;
  color: $color-gray-500;
  composes: paragraphRegular;
}

.footNote {
  margin-top: $spacing-sm;
  font-size: $font-sm;
  font-style: italic;
}
