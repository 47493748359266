@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.verifyIdentityHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  color: $color-gray-700;
  composes: headerLarge;
  margin-block: $spacing-sm;
  @media screen and (max-width: $screen-sm) {
    margin-bottom: $spacing-sm;
  }
}

.description {
  align-self: flex-start;
  width: 100%;
  padding: 0 $spacing-xl;
  margin-bottom: $spacing-sm;
  text-align: center;
  color: $color-gray-500;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-sm;
}

.buttonWrapper {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: $spacing-xl;
}

.onboardingContainer {
  @media screen and (max-width: $screen-sm) {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
}
