@import 'styles/constants.scss';

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton {
  width: 100%;
  height: 100%;
  background: $color-gray-300;
  background: linear-gradient(90deg, $color-gray-300 25%, $color-gray-200 50%, $color-gray-300 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}
