@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px); // Navbar height
  gap: $spacing-md;
}

.title {
  text-align: center;
  color: $color-gray-700;
  composes: headerLarge;
}

.description {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  text-align: center;
  color: $color-gray-500;
  gap: $spacing-2xs;
}

.actionButtons {
  display: flex;
  align-items: center;
  gap: $spacing-md;
}

.errorIcon {
  width: 100px;
  height: 100px;
}
