@import 'styles/classes/text.module.scss';
@import 'styles/constants';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    height: 450px;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  border-radius: $border-full;
  margin: 0 auto;
  background-color: $color-accent-alt;
}

.shieldIcon {
  width: 40px;
  height: 40px;
  color: $color-primary;
  stroke-width: 1.5;
}

.title {
  text-align: center;
  composes: headerLarge;
}

.description {
  text-align: center;
  color: $color-gray-600;
}

.button {
  margin-top: $spacing-xl;
}
