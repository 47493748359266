@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.quantityInput {
  width: 96px;
  height: 40px;
  border: 1px solid $color-gray-300;
  margin-right: 0;
  text-align: right;
  composes: baseInputStyling;
  &::placeholder {
    color: $color-gray-500;
  }
  &.error {
    border: 1px solid $color-red;
  }
}

.quantityInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: $spacing-3xs;
}

.errorMessage {
  text-align: right;
  color: $color-red;
  composes: paragraphMicro;
}
