@import 'styles/classes/text.module.scss';

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.bankTransferContainer {
  display: flex;
  justify-content: space-between;
  gap: $spacing-lg;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.bankTranserInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: $spacing-xl;

  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.bankTransferInfo {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.stepContents {
  display: flex;
  height: 100%;
  font-size: $font-md;
  font-weight: $weight-normal;
  color: $color-gray-600;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.stickyButtonContainer {
  @media screen and (max-width: $screen-sm) {
    position: fixed;
    z-index: $z-index-modal;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: $spacing-sm;
    background-color: white;
  }
}

.bankAccountContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    width: 100%;
    padding-bottom: 80px;
  }
}

.headingWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.bankDetailsTitle {
  composes: headerMedium;
}

.bankDetailsDescription {
  color: $color-gray-600;
}

.accountDetails {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
