@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.bannerContainer {
  display: flex;
  justify-content: center;
  padding: $spacing-sm 0;
  background-color: $color-primary;
}

.bannerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  composes: maxWidthContainer;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-sm;
  }
}

.messageWrapper {
  display: flex;
  width: 100%;
  padding-top: $spacing-4xs;
  color: white;
  gap: $spacing-2xs;
}

.title {
  display: inline-block;
  max-width: 90%;
}

.button {
  width: fit-content;
  max-height: 32px;
  padding: 0;
  font-size: $font-sm;

  @media screen and (max-width: $screen-sm) {
    margin-left: calc($spacing-md + $spacing-3xs);
  }
}

.regular {
  background-color: $color-primary;
  .button {
    color: $color-primary;
  }
}

.info {
  background-color: $color-blue;
  .button {
    color: $color-blue;
  }
}

.success {
  background-color: $color-green;
}

.announcement {
  background-color: black;
}
