@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.royaltyPayoutsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.infoIcon {
  display: inline-flex;
  width: 24px;
  height: 24px;
  composes: iconContainer;
  &:hover {
    box-shadow: none;
  }
}

.title {
  composes: headerLarge;

  @media screen and (max-width: $screen-xs) {
    font-size: $font-xl;
  }
}
