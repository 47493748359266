@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.routesContainer {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  composes: unstyledList;
  gap: $spacing-xl;

  li {
    display: flex;
    align-items: center;
    height: 32px;

    &.displayLinkNone {
      display: none;
      gap: 0;
    }
  }
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
  @media screen and (max-width: $screen-lg) {
    position: relative;
    width: auto;
    gap: $spacing-md;
  }
}

.routeLink {
  font-size: $font-sm;
  color: black;
  composes: basicLink;
}

.routeLink.activeRoute,
li > a.activeRoute,
div > a.activeRoute {
  color: $color-primary;
}
