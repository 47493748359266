@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.avatarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  gap: $spacing-2xs;
}

.owner {
  composes: headerSmall;
}
