@import 'styles/constants.scss';

.mxLogoWrapper {
  position: relative;
  z-index: $z-index-navbar;
  width: 80px;
  height: 48px;

  @media screen and (max-width: $screen-sm) {
    width: 24px;
    height: 24px;
  }
}

.mxLogo {
  width: 100%;
  height: 100%;
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.mxLogoCompact {
  display: none;
  width: 100%;
  height: 100%;
  @media screen and (max-width: $screen-sm) {
    display: block;
  }
}
