@import 'styles/classes/text.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.thumbnail {
  height: 64px;
  border-radius: $border-xs;
}

.songName {
  margin-top: $spacing-sm;
  composes: headerMedium;
}

.artistName {
  margin-top: $spacing-3xs;
  color: $color-gray-600;
}
