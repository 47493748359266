@import 'styles/constants.scss';

.agreementWrapper {
  font-size: $font-xs;
  font-weight: $weight-normal;
  color: $color-gray-600;
}

.agreementLink {
  font-size: $font-xs;
  font-weight: $weight-semibold;
  color: $color-gray-600;
}
