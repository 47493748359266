@import 'styles/constants.scss';

.alreadySoldContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    justify-content: flex-end;
    height: 55%;
  }
}

.alreadySoldTitle {
  text-align: center;
  font-size: $font-lg;
  font-weight: $weight-semibold;
  line-height: $line-height-lg;
}

.alreadySoldSubtitle {
  text-align: center;
  font-size: $font-md;
  font-weight: $weight-normal;
  line-height: $line-height-lg;
  color: $color-gray-600;
}

.closeButton {
  margin-top: $spacing-md;
}
