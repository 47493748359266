@import 'styles/constants.scss';

.orderButton {
  width: 248px;
  margin-bottom: $spacing-md;
  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.sellButton {
  background-color: $color-red;
  color: white;
  &:not(:disabled) {
    &:hover {
      background-color: $color-red-light;
    }
  }
  composes: orderButton;
}

.cancelAskButton {
  border-color: $color-red;
  color: $color-red;
  composes: orderButton;
  &:not(:disabled) {
    &:hover {
      border-color: $color-red;
      background-color: $color-red-alt;
    }
  }
}

.cancelOfferButton {
  color: $color-primary;
  composes: orderButton;
}

.uniqueOrderSectionHeader {
  margin-top: $spacing-md;
  font-size: $font-lg;
  font-weight: 600;
}

.offer {
  margin-bottom: $spacing-md;
  font-size: $font-md;
  font-weight: 400;
  color: $color-gray-600;
}

.listForSale {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.modalLoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 120px;
}
