@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-sm;
  gap: $spacing-2xs;
}

.title {
  text-transform: capitalize;
  composes: headerLarge;
}

.userDescription {
  margin-top: 0;
}

.kycStatusContainer {
  display: flex;
  align-items: center;
  margin-top: $spacing-3xs;
  gap: $spacing-2xs;
}

.icon {
  height: 24px;
  color: $color-green;
  stroke-width: 2;
}
