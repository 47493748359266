@import 'styles/classes/text.module.scss';

.addIbanContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.title {
  composes: headerLarge;
}

.subtitle {
  font-size: $font-md;
  color: $color-gray-600;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: $spacing-md;
}

.ibanNumberContainer {
  position: relative;
}

.addButton {
  margin-top: $spacing-md;
  @media screen and (max-width: $screen-sm) {
    margin-top: auto;
  }
}

.personalDetailHeader {
  font-size: $font-md;
  line-height: $line-height-lg;
  color: $color-gray-500;
}

.personalDetailText {
  font-weight: $weight-medium;
  color: black;
}
