@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.countdownWrapper {
  display: flex;
  overflow: hidden;
  width: 336px;
  height: 40px;
  border-radius: $border-xs $border-xs 0 0;
  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.statusAllocatingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  color: $color-primary;
}

.timerContainer {
  display: flex;
  width: 100%;
}

.statusLabel {
  font-size: $font-sm;
  font-weight: $weight-medium;
}

.statusLabelWrapper,
.timerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.statusLabelWrapper {
  background-color: $color-primary;
  color: white;
}

.timerWrapper {
  background-color: white;
}

.timer {
  font-weight: $weight-medium;
  color: $color-primary;
}

.title {
  color: white;
}
