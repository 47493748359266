@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  gap: $spacing-md;
}

.title {
  font-size: $font-xl;
  font-weight: $weight-bold;
}

.text {
  color: $color-gray-600;
}

.button {
  width: 100%;
}
