@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

/* Root wrapper */

.refresher {
  z-index: $z-index-backdrop !important;
}

.wrapper {
  width: 100%;
  min-height: 100dvh;
  @media screen and (max-width: $screen-sm) {
    padding-bottom: calc(
      env(safe-area-inset-bottom) + 48px
    ); // 48px is the height of the bottom navigation
  }
}

/* Root container */

.container {
  box-sizing: border-box;
  composes: maxWidthContainer;
}

.container.noPaddingContainer {
  @media screen and (max-width: $screen-lg) and (min-width: $screen-sm) {
    padding: 0 $spacing-xl;
  }
  @media screen and (max-width: $screen-sm) {
    padding: 0;
  }
}

.IonHeader {
  box-shadow: none;
}
