@import 'styles/constants.scss';

.actionsContainer {
  display: flex;
  z-index: $z-index-navbar;
  align-items: center;
  gap: $spacing-md;
  @media screen and (max-width: $screen-sm) {
    gap: $spacing-sm;
  }
}

.signInLink {
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.signUpButton {
  @media screen and (max-width: $screen-sm) {
    min-width: unset;
    max-height: 32px;
    padding: $spacing-3xs $spacing-sm;
  }
}
