@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.emptyStateMessage {
  color: $color-gray-600;
  composes: paragraphRegular;
}

.ordersAndOffersContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}
