@import 'styles/constants.scss';

.countdownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: $spacing-3xs;
}

.countdownHeader {
  font-size: $font-md;
}

.counter {
  width: 100%;
  font-size: $font-lg;
  font-weight: $weight-bold;
  color: $color-primary;
}

.launchDate {
  text-align: center;
  font-size: $font-md;
  color: $color-primary;
}
