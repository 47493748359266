@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.masterInfo {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-md;
  gap: $spacing-lg;

  @media screen and (max-width: $screen-sm) {
    flex-wrap: wrap;
    margin-bottom: $spacing-md;
    gap: $spacing-sm;
  }
}

.albumCover {
  width: 72px;
}

.coverImage {
  border-radius: $border-xs;
}

.masterTitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.masterName {
  composes: headerLarge;
}

.artistName {
  color: $color-gray-600;
}

.shareImageContainer {
  height: 72px;
}

.thumbnail {
  width: 72px;
  border-radius: $border-xs;
}

.nftImg {
  transform: translateY(-80%) translateX(110%);
  width: 40px;
  border: 2px solid white;
  border-radius: $border-xs;

  @media (max-width: $screen-sm) {
    width: 36px;
  }
}
