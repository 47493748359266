@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.socialMediaTitle {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.platformMetricsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;
  row-gap: $spacing-md;

  @media screen and (max-width: $screen-md) {
    margin-top: $spacing-2xs;
  }
}

.socialMediaFollowersContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.socialMediaContent {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
}
