@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.mobileTableContainer {
  display: none;
  flex-direction: column;
  margin-top: $spacing-md;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-sm) {
    display: flex;
  }
}

.mobileRowWrapper {
  display: flex;
  flex-direction: column;
  color: #000;
  gap: 4px;

  hr {
    margin-top: 4px;
  }

  &:last-child {
    margin: 0;

    hr {
      display: none;
    }
  }
}

.date {
  color: $color-gray-500;
}

.mobileHeader {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
}

.divider {
  margin-top: $spacing-2xs !important;
}

.total {
  white-space: nowrap;
}

.endOfList {
  text-align: center;
  color: $color-gray-600;
}
