@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

label {
  font-size: $font-sm;
  font-weight: $weight-medium;
  color: $color-gray-600;
}

.menuContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: $spacing-2xs;
}

.fieldContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: $border-sm;
  transition: $transition-base;
  gap: $spacing-2xs;
}

.menu {
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  padding: $spacing-2xs $spacing-sm;
  border: 1px solid $color-gray-300;
  border-radius: $border-sm;
  background-color: white;
  outline: none;
  text-transform: capitalize;
  font-size: $font-input;
  font-weight: $weight-medium;
  line-height: $line-height-lg;
  color: black;
  transition: $transition-base;
  appearance: none;
  cursor: pointer;
  &:hover {
    border: 1px solid $color-accent-alt;
    box-shadow: 0 0 0 1px $color-accent;
  }
  &:focus,
  &:active {
    border: 1px solid $color-primary;
  }
  &:has(+ .errorMessage) {
    border: 1px solid $color-red;
  }
}

.placeholder {
  color: $color-gray-400;
}

.icon {
  position: absolute;
  top: 12px;
  right: 12px;
  opacity: 0.25;
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.option {
  text-transform: capitalize;
  color: black;

  &:disabled {
    color: $color-gray-400;
  }
}

.errorMessage {
  font-size: $font-xs;
  line-height: $font-xs;
  color: $color-red;
}
