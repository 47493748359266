@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.informationList {
  display: flex;
  flex-direction: column;
  composes: unstyledList;
  gap: $spacing-md;
}

.informationList > li {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: $weight-medium;
  color: $color-gray-600;
}

.informationList > li > span {
  &:nth-child(2) {
    color: black;
  }
}

.highlighted > span {
  font-size: $font-lg;
  font-weight: $weight-semibold;
  color: black;
}

.dropdownValue {
  padding: $spacing-2xs;
  padding-right: 0;
  color: black;
  composes: noSelect;
}

.orderType {
  display: flex;
  align-items: center;
}

.infoIcon {
  width: 24px;
  height: 24px;
  composes: iconContainer;
  &:hover {
    box-shadow: none;
  }
}

.priceInput {
  width: 96px;
  height: 40px;
  padding-left: $spacing-lg;
  border: 1px solid $color-gray-300;
  text-align: right;
  composes: baseInputStyling;
  &::placeholder {
    color: $color-gray-500;
  }
}

.priceContainer,
.orderQuantityItem {
  composes: noSelect;
}

.orderAmountItem {
  display: flex;
  flex-direction: column;
  font-weight: $weight-medium;
}

.amountLimit {
  color: $color-gray-500;
  transition: $transition-base;
  composes: paragraphSmall;
  &.error {
    color: $color-red;
  }
}

.orderTotalItem > span {
  color: black;

  @media screen and (max-width: $screen-sm) {
    font-size: $font-lg;
    font-weight: $weight-semibold;
  }
}

.maxQuantity {
  margin-left: $spacing-2xs;
  font-size: $font-sm;
}

.red {
  color: $color-red;
}

.label {
  font-size: $font-md;
}
