@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.container {
  box-sizing: border-box;
  display: flex;
  position: relative;
  z-index: $z-index-base;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 512px;
  padding: $spacing-md;
  border-radius: $border-xs;
  margin: 0 auto;
  background-color: white;
  box-shadow: $shadow-md;
  & > h1 {
    font-size: $font-xl;
    font-weight: $weight-bold;
  }
}

@media screen and (max-width: $screen-sm) {
  .container {
    padding: $spacing-md $spacing-lg;
  }
}

@media screen and (max-width: $screen-sm) {
  .container {
    width: calc(100% - $spacing-sm);
    padding: $spacing-sm $spacing-md;
  }
}
