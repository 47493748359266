@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

/* Component wrapper */
.navbarWrapper {
  box-sizing: border-box;
  position: sticky;
  z-index: $z-index-navbar;
  top: calc(env(safe-area-inset-top) + 48px);
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 0 0 $color-gray-200;
  padding-block: $spacing-2xs;
  padding-inline: 10rem;
  @media screen and (max-width: $screen-md) {
    padding-inline: $spacing-lg;
  }
  @media screen and (max-width: $screen-sm) {
    padding-inline: $spacing-sm;
  }
}

.navBarTop {
  top: 0;
  padding-top: calc($spacing-2xs + env(safe-area-inset-top));
}

/* Main Container */
.navbarContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  height: 48px;
  margin: 0 auto;

  @media screen and (max-width: $screen-sm) {
    height: 32px;
  }
}

.backIcon {
  width: 24px;
  height: 24px;
  padding: 0;
  composes: iconContainer;
}
