@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.imoContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.imoTextContent {
  @media screen and (max-width: $screen-sm) {
    padding: 0 $spacing-sm;
  }
}

.sectionTitle {
  font-size: $font-xl;
}

.subtitle {
  color: $color-gray-600;
  composes: paragraphRegular;
}

.imoCardGroup {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;
}
