@import 'styles/constants.scss';

.emptyStateWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.emptyState {
  color: $color-gray-600;
}
