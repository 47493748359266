@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.title {
  composes: headerLarge;

  @media screen and (max-width: $screen-xs) {
    font-size: $font-xl;
  }
}

.investmentOpportunityContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;

  @media screen and (max-width: $screen-md) {
    flex-direction: column;
  }
}

.readMore {
  color: $color-primary;
  composes: basicLink;
}

.whitePaperButton {
  margin-top: $spacing-lg;
}
