@import 'styles/classes/text.module.scss';

.accountDetailWrapper {
  display: flex;
  flex-direction: column;
  font-size: $font-md;
  line-height: $line-height-lg;
}

.label {
  color: $color-gray-500;
}

.value {
  justify-content: space-between;
}

.value,
.value p {
  text-transform: capitalize;
  font-weight: $weight-semibold;
}

.bankAccountValue {
  font-weight: $weight-semibold;
  color: black;
}
