@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.imoStatisticsContainer {
  position: relative;
}

.title {
  composes: headerMedium;
}

.price {
  line-height: $line-height-xl;
  color: $color-primary;
  composes: headerLarge;
}

.footnote {
  margin-top: $spacing-sm;
  color: $color-gray-600;
  composes: paragraphRegular;
}
