@import 'styles/classes/text.module.scss';

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.artistSection,
.masterSection {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.title {
  composes: headerLarge;

  @media screen and (max-width: $screen-xs) {
    font-size: $font-xl;
  }
}

.musicPreview {
  width: 320px;
  @media screen and (max-width: $screen-xs) {
    width: 100%;
  }
}

.spotifyPlayer {
  width: 40%;
  border-radius: $border-md;
  @media screen and (max-width: $screen-md) {
    width: 100%;
  }
}
