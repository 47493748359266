@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.formGroup {
  display: flex;
  position: relative;
  position: relative;
  flex-direction: column;
  gap: $spacing-2xs;

  label {
    font-size: $font-sm;
    font-weight: $weight-medium;
    color: $color-gray-600;
  }

  input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 52px;
    padding: $spacing-sm $spacing-sm;
    border: 1px solid $color-gray-300;
    border-radius: $border-sm;
    background-color: white;
    box-shadow: none;
    outline: none;
    font-size: $font-input;
    font-weight: $weight-medium;
    line-height: $line-height-sm;
    transition: $transition-base;
    appearance: none;
    cursor: text;
    &::placeholder {
      color: $color-gray-400;
    }
    &:hover {
      border: 1px solid $color-accent-alt;
      box-shadow: 0 0 0 1px $color-accent;
    }
    &:focus,
    &:active {
      border: 1px solid $color-primary;
    }
    &:has(+ .errorMessage) {
      border: 1px solid $color-red;
    }
  }

  input[type='date'] {
    position: relative;
    width: 100%;
    height: 52px;
    transition: $transition-base;
    &:invalid::-webkit-datetime-edit {
      color: $color-gray-400;
    }
    &::-webkit-calendar-picker-indicator:hover {
      transform: scale(105%);
      cursor: pointer;
    }
  }
}

.formGroupDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.fieldContainer {
  position: relative;
}

.endAdornment {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding-right: $spacing-sm;
}

.errorMessage {
  font-size: $font-xs;
  line-height: $font-xs;
  color: $color-red;
}
