@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.traderSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}

.traderContainer {
  display: flex;
  flex-direction: column;
}

.traderWrapper {
  min-height: 275px;
  padding: $spacing-md;
}

.traderVariantWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    height: 100%;

    &.keyboardActive {
      height: unset;
    }
  }
}

.buyButton {
  color: white;
}
