@import 'styles/constants.scss';

.accountPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: $spacing-lg 0;
}

.tabBar {
  margin-bottom: $spacing-lg;
}
