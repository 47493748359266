@import 'styles/constants.scss';

.title {
  margin-bottom: $spacing-sm;
  text-align: center;
  font-size: $font-xl;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.successDescription {
  text-align: center;
  font-size: $font-sm;
  font-weight: $weight-normal;
  line-height: $line-height-md;
  color: $color-gray-500;
}

.button {
  background-color: $color-primary;
}

.iconWrapper {
  width: 96px;
  height: 96px;
  margin-bottom: $spacing-lg;
}
