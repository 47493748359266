@import 'styles/classes/text.module.scss';

.orderCompletedContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.title {
  text-align: center;
  composes: headerLarge;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    justify-content: center;
    height: 100%;
  }
}

.sharesCount {
  color: $color-gray-600;
  composes: paragraphSmall;
}

.imageContainer {
  position: relative;
}

.albumCover {
  width: 48px;
  height: 48px;
  border-radius: $border-xs;
}

.orderCompletedAnimation {
  position: absolute;
  top: -420%;
  left: -370%;
  width: 400px;
  height: 400px;
  pointer-events: none;
}

.masterInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.masterName {
  font-weight: $weight-semibold;
  composes: headerMedium;
}

.artistName {
  color: $color-gray-600;
  composes: paragraphRegular;
}

.bodyText {
  padding: 0 $spacing-xl;
  text-align: center;
  color: $color-gray-700;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-top: $spacing-2xs;
  gap: $spacing-sm;
}
