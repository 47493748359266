@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

.masterContents {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
}

.artistName {
  font-size: $font-lg;
  font-weight: $weight-semibold;
  line-height: $line-height-sm;
}

.masterName {
  font-weight: $weight-bold;
  line-height: $line-height-sm;
}

.smallMasterName {
  font-size: $font-xl;
}

.largeMasterName {
  font-size: $font-2xl;
  @media screen and (max-width: $screen-sm) {
    font-size: $font-xl;
  }
}
