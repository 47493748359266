@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.sharesContainer {
  padding-bottom: $spacing-2xl;
  @media screen and (max-width: $screen-sm) {
    gap: $spacing-sm;
  }
}

.heading {
  font-size: $font-xl;
  @media screen and (max-width: $screen-sm) {
    font-size: $font-lg;
  }
}

.actions {
  width: auto;
  @media screen and (max-width: $screen-sm) {
    width: 40%;
    margin-left: auto;
    text-align: right;
  }
}

.actionButton {
  color: $color-primary;
  composes: basicLink;
  &.green {
    color: $color-green;
  }
  &.red {
    color: $color-red;
  }
}

.italizedLabel {
  font-style: italic;
  font-weight: 400;
  color: $color-gray-600;
}

.filterBar {
  display: flex;
  margin-bottom: $spacing-md;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.filterButtonContainer {
  display: flex;
  gap: $spacing-sm;
}

.tableHeader > div,
.tableRow {
  justify-content: flex-start;
  gap: $spacing-3xl;

  @media screen and (max-width: $screen-sm) {
    gap: $spacing-lg;
  }

  @media screen and (max-width: $screen-xs) {
    padding: 0 $spacing-2xs;
    gap: $spacing-sm;
  }
}

.tableRow {
  height: 72px;
}

.sellButton {
  color: $color-red;
  composes: basicLink;
}

.buyButton {
  color: $color-primary;
  composes: basicLink;
}

.customCheckbox {
  composes: baseCheckboxStyling;
}

// Columns
.checkButton {
  width: 20px;
  height: 20px;
}

.coverImage {
  width: 32px;
  height: 32px;
  border-radius: $border-xs;
  margin: auto 0;
}

.index {
  width: 100px;
  color: $color-gray-600;

  @media screen and (max-width: $screen-xs) {
    width: 90px;
  }
}

.price {
  width: 80px;
}

.owned {
  width: 50px;
}

.nftImg {
  transform: scale(0.7) translateY(-100%) translateX(50%);
  border: 2px solid white;
  border-radius: $border-xs;
}

.royalyShareHeader {
  margin-bottom: $spacing-lg;
}

.royaltyShareFirstLine {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.royaltyShareHeading {
  font-size: $font-xl;
}

.tag {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0 $spacing-2xs;
  border-radius: $border-full;
  background-color: $color-gray-200;
  font-size: $font-sm;
  color: $color-gray-500;
}

.royaltyShareBodyText {
  font-size: $font-md;
  color: $color-gray-600;
}
