@import 'styles/classes/common.module.scss';

.filterButton {
  composes: pill;
}

.active {
  border-color: $color-accent;
  background-color: $color-accent;
  color: $color-primary;
}

.closeIcon {
  display: block;
}
