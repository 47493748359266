@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

$dropdown-min-width: 176px;

.dropdownContainer {
  position: relative;

  &.fixed {
    width: 120px;
  }
}

.dropdownButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: $transition-base;
  cursor: pointer;
  &.active,
  &:hover {
    background-color: $color-gray-200;
    box-shadow: 0 0 0 2px $color-gray-200;
    &:active {
      scale: 0.95;
    }
  }
}

.primary {
  color: $color-primary;
  &:hover {
    color: $color-primary-alt;
  }
  svg {
    color: $color-primary;
  }
  &.active {
    color: $color-primary-alt;
    svg {
      color: $color-primary-alt;
    }
  }
}

.secondary {
  color: $color-gray-600;
  &:hover {
    color: black;
  }
  svg {
    color: $color-gray-600;
  }
  &.active {
    color: black;
    svg {
      color: black;
    }
  }
}

.dropdown {
  border-radius: $border-xs;
  svg {
    transition: $transition-base;
  }
  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.menu {
  composes: hamburgerMenu;
}

.icon {
  width: 32px;
  height: 32px;
  padding: $spacing-3xs;
  margin-left: $spacing-2xs;
  svg > * {
    transition: $transition-base;
  }
  &.noMargin {
    margin-left: 0;
  }
}

.optionsList {
  position: absolute;
  z-index: $z-index-navbar;
  min-width: $dropdown-min-width;
  padding: $spacing-2xs;
  border-radius: $border-sm;
  margin-top: $spacing-sm;
  background-color: white;
  composes: unstyledList;
  composes: elevatedUi;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.option {
  border-radius: $border-xs;
  transition: $transition-base;
  composes: noSelect;
  cursor: pointer;

  a,
  span {
    display: block;
    width: 100%;
    padding: $spacing-2xs;
    border-radius: $border-xs;
  }

  &:hover {
    background-color: $color-gray-200;
    color: black;
  }

  &:active {
    transform: scale(0.98);
    background-color: $color-gray-300;
  }

  &.disabled {
    a,
    span {
      color: $color-gray-400;
      cursor: default;

      &:active {
        transform: scale(1);
        background-color: $color-gray-200;
      }
    }
  }
}
