@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

$list-max-height-md: 440px;
$list-max-height-lg: 580px;

.orderListWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: $spacing-sm;
  @media screen and (max-width: $screen-sm) {
    padding: $spacing-xl 0;
    border-top: 1px solid $color-gray-300;
    gap: $spacing-md;
  }
  &.noGap {
    gap: $spacing-3xs;
  }
}

.title {
  font-weight: $weight-medium;
  composes: paragraphRegular;
}

.subtitle {
  padding-bottom: $spacing-sm;
  font-size: small;
  color: $color-gray-600;
  composes: paragraphMicro;
}

.orderListContainer {
  position: relative;
  max-height: $list-max-height-md;
  flex-grow: 1;

  &.withGradient {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 10%,
        rgba(255, 255, 255, 0) 90%,
        rgba(255, 255, 255, 1) 100%
      );
      pointer-events: none;
    }
  }
  &.heightLarge {
    max-height: $list-max-height-lg;
  }
}

.orderList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  composes: unstyledList;
  gap: $spacing-sm;
}

.orderItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: $spacing-sm;
  border-radius: $border-xs;
  background-color: $color-gray-200;
  gap: $spacing-sm;
}

.orderItem > img {
  width: 36px;
  height: 36px;
  border-radius: $border-xs;
}

.orderItem > span {
  margin-left: auto;
  font-size: $font-md;
}

.tradePrice,
.tokenId {
  font-weight: $weight-medium;
}

.shareImageContainer {
  height: 36px;
}

.thumbnail {
  width: 36px;
  border-radius: $border-xs;
}

.nftImg {
  transform: translateY(-80%) translateX(80%);
  width: 24px;
  border: 2px solid white;
  border-radius: $border-xs;
}
