@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.confirmationContainer {
  display: flex;
  flex-direction: column;
  overflow: unset !important;
  gap: $spacing-md;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.title {
  composes: headerLarge;
}

.label {
  display: flex;
  justify-content: space-between;
  font-size: $font-md;
  font-weight: $weight-normal;
}

.value {
  color: $color-gray-600;
}

.totalLabel {
  font-weight: $weight-semibold;
  composes: label;
}

.totalValue {
  color: black;
}

.paymentMethodContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.paymentMethod {
  display: flex;
  justify-content: space-between;
  font-size: $font-md;
  font-weight: $weight-normal;
}

.cardDetails {
  display: flex;
  align-items: center;
  gap: $spacing-sm;
}

.terms {
  margin-bottom: $spacing-xl;
  font-size: $font-xs;
  font-weight: $weight-normal;
  color: $color-gray-600;
}

.confirmButton {
  @media screen and (max-width: $screen-sm) {
    margin-top: auto;
  }
}
