@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.orderTypesContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.title {
  composes: headerLarge;
}

.subtitle {
  font-size: $font-md;
  font-weight: $weight-medium;
  composes: paragraphRegular;
}

.message {
  font-size: $font-sm;
  color: $color-gray-600;
}
