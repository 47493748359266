@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
}

.title {
  font-size: $font-lg;
}

.subtitle {
  font-size: $font-md;
  font-weight: $weight-medium;
  composes: paragraphRegular;
}

.message {
  white-space: pre-line;
  font-size: $font-sm;
  color: $color-gray-600;
}
