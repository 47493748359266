@import 'styles/constants.scss';

.ownersWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-2xs;
}

.ownerName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $color-gray-600;
}
