@import 'styles/classes/common.module.scss';
@import 'styles/constants.scss';

.closeButton {
  width: 32px;
  height: 32px;
  composes: iconContainerDark;
}

.bannerWrapper {
  flex-direction: row;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
  }
}

.success {
  @media screen and (max-width: $screen-sm) {
    flex-direction: row !important;
  }
}
