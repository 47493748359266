@import 'styles/constants.scss';
@import 'styles/classes/text.module.scss';

.yieldContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-2xs;
}

.title {
  composes: headerSmall;
}

.percentage {
  color: $color-primary;
  composes: headerMedium;
}

.period {
  color: $color-gray-600;
  composes: paragraphRegular;
}
