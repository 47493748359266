@import 'styles/classes/common.module.scss';
@import 'styles/classes/text.module.scss';

.updatePasswordContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-xl;

  @media screen and (max-width: $screen-sm) {
    justify-content: center;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-md;
}

.title {
  text-align: center;
  composes: headerLarge;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-3xs;
}

.message {
  text-align: center;
  font-size: $font-sm;
  color: $color-gray-600;
}

.email {
  font-weight: $weight-semibold;
  color: $color-gray-600;
}

.timer {
  font-weight: $weight-semibold;
  color: $color-primary;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: $color-gray-300;
}
