@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.tableRow {
  height: 72px;
}

.song {
  width: 150px;
}

.artist {
  width: 80px;
}

.soldDuringImo {
  width: 100px;
}

.listedDuringImo {
  width: 120px;
}

.totalShares {
  width: 80px;
}

.earningsFromImo {
  width: 120px;
}

.earningsFromTradingFees {
  width: 100px;
}

.thumbnail {
  display: flex;
  justify-content: right;
  width: 40px;
}

.thumbnailImg {
  width: 40px;
  height: 40px;
  border-radius: $border-xs;
}
