@import 'styles/classes/text.module.scss';
@import 'styles/classes/common.module.scss';

.accordionHeaderWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-2xs;
}

.accordionTitle {
  font-size: $font-md;
  font-weight: $weight-semibold;
}

.rowCount {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  padding: 0 $spacing-2xs;
  border-radius: $border-full;
  background-color: $color-primary-light;
  color: $color-primary;
  p {
    font-size: $font-sm;
    font-weight: $weight-semibold;
  }
}
