@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.browseSongsContainer {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    padding: 0 $spacing-sm;
  }
}

.sectionTitle {
  font-size: $font-xl;
}

.browseSongCardsGroup {
  display: grid;
  gap: $spacing-xl $spacing-sm;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: $screen-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: $screen-xs) {
    gap: $spacing-lg;
    grid-template-columns: 1fr;
  }
}
