@import 'styles/classes/text.module.scss';

.paymentMethodContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: $spacing-md;
}

.topWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: $spacing-md;
}

.bottomWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-sm;
  @media screen and (max-width: $screen-sm) {
    margin-top: auto;
  }
}

.title {
  composes: headerLarge;
}

.paymentMethods {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-2xs;
}

.footer {
  text-align: center;
  font-size: $font-md;
  font-weight: $weight-medium;
}

.footer > a {
  color: $color-gray-500;
}
