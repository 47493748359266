@import 'styles/constants.scss';
@import 'styles/classes/common.module.scss';

$button-min-width: 136px;

.buttonBase {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: $button-min-width;
  padding: $spacing-2xs $spacing-md;
  border: 0;
  border-radius: $border-xs;
  margin: 0;
  font-size: $font-md;
  font-weight: $weight-medium;
  line-height: $line-height-2xl;
  transition: $transition-base;
  composes: noSelect;
  cursor: pointer;

  svg {
    // Use transforms to create a visual offset, but maintain DOM structure
    width: 32px;
    height: 32px;
    padding: $spacing-3xs;
  }
  &:not(:disabled) {
    &:hover {
      scale: 0.98;
    }
    &:active {
      scale: 0.95;
    }
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.primary {
  background-color: $color-primary;
  color: #fff;
  composes: buttonBase;
  a {
    color: white;
    transition: $transition-base;
  }
  &:not(:disabled) {
    &:hover {
      background-color: $color-primary-alt;
    }
  }
}

.secondary {
  box-sizing: border-box;
  border: 2px solid $color-primary;
  background-color: white;
  color: $color-primary;
  composes: buttonBase;
  &:not(:disabled) {
    &:hover {
      border: 2px solid $color-primary-alt;
      background-color: $color-accent;
      a {
        color: $color-primary-alt;
        transition: $transition-base;
      }
    }
  }
}

.ghost {
  background-color: white;
  color: $color-primary;
  composes: buttonBase;
  &:not(:disabled) {
    &:hover {
      background-color: $color-accent;
      a {
        color: $color-primary-alt;
        transition: $transition-base;
      }
    }
  }
}

.outline {
  border: 1px solid $color-primary;
  background-color: white;
  color: $color-primary;
  composes: buttonBase;
}

.danger {
  background-color: $color-red;
  color: white;
  composes: buttonBase;
  &:not(:disabled) {
    &:hover {
      background-color: $color-red-light;
    }
  }
}

.small {
  min-width: 0;
  padding: 0 $spacing-2xs;
  background-color: transparent;
  color: $color-primary;
  composes: buttonBase;

  &:not(:disabled) {
    &:hover {
      background-color: $color-accent;
    }
  }
}

.fluidButton {
  width: 100%;
  &:not(:disabled) {
    &:hover {
      scale: 0.99;
    }
    &:active {
      scale: 0.96;
    }
  }
}
