@import 'styles/constants.scss';

/* The switch - the box around the slider */

$switch-width: 50px;
$switch-height: 30px;
$switch-circle-size: 26px;

.switch {
  display: inline-block;
  position: relative;
  width: $switch-width;
  height: $switch-height;
}

/* Hide default HTML checkbox */
.switchInput {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $switch-height;
  background-color: $color-gray-300;
  transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
}

.slider::before {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 2px;
  width: $switch-circle-size;
  height: $switch-circle-size;
  border-radius: 50%;
  background-color: white;
  transition: 0.4s;
  transition: 0.4s;
  filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0 3px 8px rgba(0, 0, 0, 0.15));
}

.switchInput:checked + .slider {
  background-color: $color-primary;
}

.switchInput:focus + .slider {
  box-shadow: 0 0 1px $color-primary;
}

.switchInput:checked + .slider::before {
  transform: translateX(calc(#{$switch-width} - #{$switch-circle-size} - 4px));
}

.switchDisabled {
  opacity: 0.5;
  pointer-events: none;
}
