@import 'styles/classes/text.module.scss';
@import 'styles/constants.scss';

.upcomingContainer {
  display: flex;
  flex-direction: column;
  padding: $spacing-lg;
  gap: $spacing-md;
}

.sectionTitle {
  font-size: $font-xl;
}

.upcomingSongsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-md;
}

.masterCard {
  display: flex;
  flex-direction: column;
  gap: $spacing-md;

  @media screen and (max-width: $screen-sm) {
    width: 100%;
  }
}

.masterOwnerWrapper {
  position: absolute;
  top: $spacing-md;
}
